export const BankRecords = [
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0001",
        Branch_Information: "ANZ Retail 1",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0004",
        Branch_Information: "ANZ Retail 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0008",
        Branch_Information: "International Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0009",
        Branch_Information: "ANZ Retail 3",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0011",
        Branch_Information: "Head Office",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0030",
        Branch_Information: "Bonus Bonds",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0034",
        Branch_Information: "Online Business",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0042",
        Branch_Information: "Asian Banking Central",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0050",
        Branch_Information: "ANZ Contact Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0053",
        Branch_Information: "ANZ Custodian Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0055",
        Branch_Information: "EDS Test Branch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0058",
        Branch_Information: "ANZ NZ Headquarters",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0066",
        Branch_Information: "Admin Test File",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0069",
        Branch_Information: "Systems Test Branch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0070",
        Branch_Information: "Lending Service",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0071",
        Branch_Information: "New Resident Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0075",
        Branch_Information: "ANZ Retail 7",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0077",
        Branch_Information: "ANZ Retail 8",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0078",
        Branch_Information: "ANZ Retail 9",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0079",
        Branch_Information: "Palmerston North Trans PC",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0080",
        Branch_Information: "ANZ Wealth",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0083",
        Branch_Information: "ANZ Wealth",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0084",
        Branch_Information: "Transaction Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0085",
        Branch_Information: "OSC Southern Zone TPC Wellington Support",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0088",
        Branch_Information: "ANZ AS/400 Test",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0091",
        Branch_Information: "Conversion Branch Exception",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0092",
        Branch_Information: "Personal Credit Management",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0102",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0107",
        Branch_Information: "Credit Assessment",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0113",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0121",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0125",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0126",
        Branch_Information: "ANZ Group Credit Cards",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0129",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0137",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0141",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0142",
        Branch_Information: "St Lukes ",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0143",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0147",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0154",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0161",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0165",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0170",
        Branch_Information: "Howick",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0171",
        Branch_Information: "Royal Oak",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0178",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0182",
        Branch_Information: "Glenfield Mall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0183",
        Branch_Information: "Stoddard Road",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0186",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0190",
        Branch_Information: "Manukau",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0194",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0202",
        Branch_Information: "Newton",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0204",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0205",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0210",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0215",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0218",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0221",
        Branch_Information: "Botany Downs",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0226",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0234",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0235",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0236",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0242",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0244",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0249",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0258",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0262",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0270",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0274",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0277",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0281",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0286",
        Branch_Information: "Takanini",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0288",
        Branch_Information: "Waiheke Island",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0295",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0297",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0298",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0302",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0307",
        Branch_Information: "Dargaville",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0310",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0311",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0315",
        Branch_Information: "Hamilton",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0321",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0322",
        Branch_Information: "Tauranga Crossing",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0325",
        Branch_Information: "Northwood",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0327",
        Branch_Information: "Frankton 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0330",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0331",
        Branch_Information: "Kerikeri 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0338",
        Branch_Information: "Kaitaia",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0349",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0353",
        Branch_Information: "Kaitaia",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0354",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0362",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0367",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0370",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0373",
        Branch_Information: "Mount Maunganui",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0381",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0382",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0387",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0391",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0395",
        Branch_Information: "Paeroa",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0398",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0403",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0414",
        Branch_Information: "Rotorua Mall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0422",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0425",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0427",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0434",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0438",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0439",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0447",
        Branch_Information: "Te Kuiti",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0450",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0451",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0455",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0461",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0467",
        Branch_Information: "Rotorua",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0475",
        Branch_Information: "Bayfair",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0482",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0486",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0487",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0495",
        Branch_Information: "NorthWest",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0504",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0505",
        Branch_Information: "Wellington",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0509",
        Branch_Information: "Victoria St Central",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0514",
        Branch_Information: "Christchurch SPB Branch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0517",
        Branch_Information: "Courtenay Place",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0519",
        Branch_Information: "Johnsonville",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0527",
        Branch_Information: "North Lambton Quay",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0530",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0533",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0535",
        Branch_Information: "Merchant Business Solutions",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0537",
        Branch_Information: "Victoria St Central",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0542",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0546",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0553",
        Branch_Information: "Tawa",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0557",
        Branch_Information: "Wellington Travel",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0564",
        Branch_Information: "Wellington 3",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0586",
        Branch_Information: "Wellington 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0598",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0607",
        Branch_Information: "Lower Hutt 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0611",
        Branch_Information: "Dannevirke",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0623",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0625",
        Branch_Information: "Feilding",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0635",
        Branch_Information: "Northlands",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0641",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0646",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0650",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0651",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0653",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0662",
        Branch_Information: "Taradale",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0664",
        Branch_Information: "University Of Canterbury",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0666",
        Branch_Information: "Levin",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0671",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0676",
        Branch_Information: "Chatham Islands",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0677",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0678",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0681",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0682",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0685",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0691",
        Branch_Information: "Storford Lodge",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0695",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0697",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0702",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0707",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0721",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0723",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0731",
        Branch_Information: "Paraparaumu",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0735",
        Branch_Information: "Silverdale",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0745",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0748",
        Branch_Information: "Bethlehem",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0753",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0754",
        Branch_Information: "Palmerston North",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0755",
        Branch_Information: "Terrace End",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0759",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0761",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0763",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0769",
        Branch_Information: "ANZ Bank",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0770",
        Branch_Information: "Gisborne 3",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0771",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0777",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0778",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0782",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0787",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0790",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0795",
        Branch_Information: "Waverley",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0797",
        Branch_Information: "Christchurch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0798",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0804",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0806",
        Branch_Information: "The Palms",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0811",
        Branch_Information: "Hornby",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0815",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0819",
        Branch_Information: "Riccarton",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0822",
        Branch_Information: "Sydenham",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0825",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0833",
        Branch_Information: "Ferrymead",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0834",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0841",
        Branch_Information: "Geraldine",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0843",
        Branch_Information: "Greymouth",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0853",
        Branch_Information: "Kaiapoi Service Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0867",
        Branch_Information: "Five Mile",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0877",
        Branch_Information: "Rangiora",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0885",
        Branch_Information: "Temuka",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0886",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0893",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0902",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0906",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0907",
        Branch_Information: "Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0913",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0914",
        Branch_Information: "Balclutha",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0926",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0961",
        Branch_Information: "ANZ Retail",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0963",
        Branch_Information: "Hokitika",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0964",
        Branch_Information: "Oamaru",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0979",
        Branch_Information: "Auckland South Travel",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0981",
        Branch_Information: "Direct Business Banking Central",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1101",
        Branch_Information: "Auckland Support Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1103",
        Branch_Information: "Auckland (Queen and Victoria Streets) 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1104",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1105",
        Branch_Information: "Business Bank Auckland North Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1106",
        Branch_Information: "Business Bank Auckland Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1107",
        Branch_Information: "Christchurch International Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1108",
        Branch_Information: "Business Bank Auckland South Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1109",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1110",
        Branch_Information: "The Warehouse Manukau 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1111",
        Branch_Information: "Business Centre Invercargill",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1112",
        Branch_Information: "Business Bank Tauranga",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1113",
        Branch_Information: "Business Bank Southern Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1114",
        Branch_Information: "Business Bank ANZIB Auckland",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1115",
        Branch_Information: "Banking Centre Nelson",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1116",
        Branch_Information: "National Mutual",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1117",
        Branch_Information: "Business Banking Hamilton",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1118",
        Branch_Information: "New Plymouth 3",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1119",
        Branch_Information: "Auckland Business Direct Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1120",
        Branch_Information: "Stortford Lodge",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1121",
        Branch_Information: "Browns Bay 1",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1122",
        Branch_Information: "Johnsonville 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1123",
        Branch_Information: "Wanganui 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1124",
        Branch_Information: "Masterton 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1125",
        Branch_Information: "Invercargill 4",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1128",
        Branch_Information: "Business Banking Central Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1129",
        Branch_Information: "Business Banking Southern Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1130",
        Branch_Information: "Business Banking Centre Dunedin",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1131",
        Branch_Information: "Dunedin",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1132",
        Branch_Information: "Business Banking - Central Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1133",
        Branch_Information: "Telephone Banking Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1134",
        Branch_Information: "Stortford Lodge 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1135",
        Branch_Information: "Business Banking Central Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1136",
        Branch_Information: "Asset Management",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1137",
        Branch_Information: "Business Banking, Southern Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1138",
        Branch_Information: "Central Zone Relation",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1139",
        Branch_Information: "Banking Centre Invercargill",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1140",
        Branch_Information: "Albany",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1141",
        Branch_Information: "Albany Instore",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1142",
        Branch_Information: "Lincoln North 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1143",
        Branch_Information: "Panmure 3",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1144",
        Branch_Information: "Ashburton Business Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1145",
        Branch_Information: "Private Banking Auckland",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1146",
        Branch_Information: "Private Banking Wellington",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1147",
        Branch_Information: "Business Banking Southern Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1148",
        Branch_Information: "Private Bank Christchurch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1149",
        Branch_Information: "Timaru Business Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1150",
        Branch_Information: "Central Region Relationship Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1151",
        Branch_Information: "Central Region Relationship Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1152",
        Branch_Information: "Rangiora Business Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1153",
        Branch_Information: "Christchurch Business Banking Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1155",
        Branch_Information: "Sydenham Business Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1156",
        Branch_Information: "Dunedin Business Banking Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1157",
        Branch_Information: "Kaiapoi 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1158",
        Branch_Information: "The Palms",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1159",
        Branch_Information: "Lincoln North",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1160",
        Branch_Information: "Papanui 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1161",
        Branch_Information: "Business Banking Central Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1162",
        Branch_Information: "Business Banking Central Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1163",
        Branch_Information: "Havelock North 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1164",
        Branch_Information: "Business Banking Christchurch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1165",
        Branch_Information: "Institutional Bank",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1166",
        Branch_Information: "International Services Auckland",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1167",
        Branch_Information: "Waikanae",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1168",
        Branch_Information: "Business Banking Southern Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1169",
        Branch_Information: "Banking Centre Christchurch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1170",
        Branch_Information: "International Services, Auckland South",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1171",
        Branch_Information: "International Services, Auckland North",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1172",
        Branch_Information: "Richmond",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1173",
        Branch_Information: "Papatoetoe 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1174",
        Branch_Information: "Panmure 2 (Ex ANZ Mt Wellington)",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1175",
        Branch_Information: "Papakura 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1176",
        Branch_Information: "Merivale 3",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1177",
        Branch_Information: "Shirley",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1178",
        Branch_Information: "Business Bank Auckland West Mid Market",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1179",
        Branch_Information: "International Operations",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1180",
        Branch_Information: "Milford 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1181",
        Branch_Information: "Orewa",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1182",
        Branch_Information: "Business Banking Property Unit",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1183",
        Branch_Information: "Business Banking Corporate Nrthrn Region",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1184",
        Branch_Information: "Bayfair 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1185",
        Branch_Information: "Upper Hutt 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1186",
        Branch_Information: "UDC Property Lending",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1187",
        Branch_Information: "Hamilton 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1188",
        Branch_Information: "Merivale",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1189",
        Branch_Information: "Riccarton Business Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1190",
        Branch_Information: "New Lynn 4",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1191",
        Branch_Information: "Paihia",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1192",
        Branch_Information: "Chatham Islands",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1193",
        Branch_Information: "Bayfair",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1194",
        Branch_Information: "Business Banking Centre Christchurch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1195",
        Branch_Information: "Manukau City 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1197",
        Branch_Information: "Central Region Relationship Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1198",
        Branch_Information: "Katikati 2",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1199",
        Branch_Information: "Havelock North",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1800",
        Branch_Information: "Auckland Bureau",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1801",
        Branch_Information: "Wellington Bureau De Change",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1802",
        Branch_Information: "Christchurch Bureau",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1803",
        Branch_Information: "Queenstown Bureau",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1804",
        Branch_Information: "Botany Downs Town Centre",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1805",
        Branch_Information: "ANZ E*Trade Support",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1806",
        Branch_Information: "Bureau De Change - 95 Queen Street",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1807",
        Branch_Information: "Loan And Building Society",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1808",
        Branch_Information: "International Services Assist",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1809",
        Branch_Information: "Origin Mortgage Management Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1811",
        Branch_Information: "268 Queen Street",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1812",
        Branch_Information: "Cib-Institutional Resources",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1813",
        Branch_Information: "Cib-Institutional Retail And Distribution",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1814",
        Branch_Information: "Cib-Institutional Telcos And Media",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1815",
        Branch_Information: "Auckland Voucher Processing",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1816",
        Branch_Information: "Transaction Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1817",
        Branch_Information: "Christchurch Voucher Processing",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1818",
        Branch_Information: "ANZ Cards Issuing",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1819",
        Branch_Information: "Personal Loans",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1820",
        Branch_Information: "Institutional Banking Branch",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1821",
        Branch_Information: "ANZ University of Canterbury",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1822",
        Branch_Information: "Whangaparaoa",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1823",
        Branch_Information: "Courtenay Place",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1824",
        Branch_Information: "Andrews Avenue Lower Hutt",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1825",
        Branch_Information: "Westgate",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1826",
        Branch_Information: "Commercial Auckland North",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1827",
        Branch_Information: "Commercial Auckland CBD",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1828",
        Branch_Information: "Commercial Auckland South",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1829",
        Branch_Information: "Commercial Bay of Plenty",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1830",
        Branch_Information: "Commercial Waikato",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1831",
        Branch_Information: "Commercial Central",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1832",
        Branch_Information: "Commercial Wellington",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1833",
        Branch_Information: "Commercial Canterbury West Coast",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1834",
        Branch_Information: "Commercial Otago Southland",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1835",
        Branch_Information: "ANZ LynnMall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1836",
        Branch_Information: "ANZ Trade and Transaction Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1837",
        Branch_Information: "ANZ Sylvia Park",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1838",
        Branch_Information: "Albany Mall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1839",
        Branch_Information: "Auckland (Queen and Victoria Streets)",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1840",
        Branch_Information: "Mount Eden",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1841",
        Branch_Information: "St Lukes Mall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1842",
        Branch_Information: "New Resident Services",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1843",
        Branch_Information: "ANZ Asian Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1844",
        Branch_Information: "WestCity",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1845",
        Branch_Information: "ANZ On Line Business",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1846",
        Branch_Information: "Manukau Mall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1847",
        Branch_Information: "Royal Oak",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1848",
        Branch_Information: "Glenfield Mall",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1849",
        Branch_Information: "Grey Lynn",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1850",
        Branch_Information: "Takanini",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1851",
        Branch_Information: "Northwood",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1852",
        Branch_Information: "Silverdale",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1853",
        Branch_Information: "Transaction Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1854",
        Branch_Information: "Transaction Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1888",
        Branch_Information: "ANZ Asian Banking",
        Bank_Number: "01",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1889",
        Branch_Information: "CTM Processing",
        Bank_Number: "01",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0018",
        Branch_Information: "Northern Regional Office",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0040",
        Branch_Information: "Dunedin Proof Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0100",
        Branch_Information: "Auckland",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0108",
        Branch_Information: "Downtown",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0110",
        Branch_Information: "St Lukes Mall",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0112",
        Branch_Information: "Birkenhead",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0120",
        Branch_Information: "Browns Bay",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0124",
        Branch_Information: "Auckland International Airport",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0128",
        Branch_Information: "Customs Street",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0130",
        Branch_Information: "BNZ International Services Auckland",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0135",
        Branch_Information: "International Operations Northern Region",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0136",
        Branch_Information: "Devonport",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0139",
        Branch_Information: "Kumeu",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0144",
        Branch_Information: "Dominion Road",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0148",
        Branch_Information: "Glen Innes",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0151",
        Branch_Information: "John Henry Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0152",
        Branch_Information: "Henderson",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0157",
        Branch_Information: "Glen Eden",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0159",
        Branch_Information: "University Of Auckland",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0160",
        Branch_Information: "Auckland Central Banking Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0167",
        Branch_Information: "Hunters Corner",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0168",
        Branch_Information: "Howick",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0176",
        Branch_Information: "BNZ Dominion Road",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0184",
        Branch_Information: "New Lynn",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0191",
        Branch_Information: "Manukau Banking Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0192",
        Branch_Information: "Newmarket",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0200",
        Branch_Information: "Karangahape Road",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0208",
        Branch_Information: "Onehunga",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0213",
        Branch_Information: "Onehunga East",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0214",
        Branch_Information: "Highbrook Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0216",
        Branch_Information: "Otahuhu",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0223",
        Branch_Information: "Pakuranga",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0224",
        Branch_Information: "Sylvia Park Branch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0232",
        Branch_Information: "Papatoetoe",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0238",
        Branch_Information: "Parnell",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0240",
        Branch_Information: "Penrose",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0248",
        Branch_Information: "Ponsonby",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0256",
        Branch_Information: "Remuera",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0261",
        Branch_Information: "Remuera",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0264",
        Branch_Information: "Mt Eden",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0271",
        Branch_Information: "Milford",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0272",
        Branch_Information: "BNZ Takapuna",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0278",
        Branch_Information: "Link Drive",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0280",
        Branch_Information: "Town Hall",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0290",
        Branch_Information: "262 Queen Street",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0300",
        Branch_Information: "Cambridge",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0304",
        Branch_Information: "Coromandel",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0308",
        Branch_Information: "Dargaville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0312",
        Branch_Information: "Frankton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0316",
        Branch_Information: "Hamilton Banking Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0320",
        Branch_Information: "Hamilton North",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0324",
        Branch_Information: "Helensville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0328",
        Branch_Information: "The Base",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0332",
        Branch_Information: "Kaikohe Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0336",
        Branch_Information: "Kaitaia",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0340",
        Branch_Information: "Cameron Road Banking Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0341",
        Branch_Information: "Bryce Street",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0342",
        Branch_Information: "Hamilton East",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0343",
        Branch_Information: "University Of Waikato",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0348",
        Branch_Information: "Whakatane",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0352",
        Branch_Information: "Kerikeri",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0358",
        Branch_Information: "Manurewa",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0360",
        Branch_Information: "Matamata",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0364",
        Branch_Information: "Maungaturoto",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0368",
        Branch_Information: "Morrinsville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0372",
        Branch_Information: "Mt Maunganui",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0376",
        Branch_Information: "Ngaruawahia",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0378",
        Branch_Information: "Rotorua Central",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0380",
        Branch_Information: "Ngatea",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0386",
        Branch_Information: "Paihia",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0388",
        Branch_Information: "Opotiki",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0390",
        Branch_Information: "Orewa",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0392",
        Branch_Information: "Otorohanga",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0396",
        Branch_Information: "Paeroa Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0400",
        Branch_Information: "Papakura",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0404",
        Branch_Information: "Pukekohe",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0408",
        Branch_Information: "Putaruru",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0410",
        Branch_Information: "Chartwell",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0412",
        Branch_Information: "Rotorua",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0416",
        Branch_Information: "Rotorua",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0424",
        Branch_Information: "Rotorua",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0428",
        Branch_Information: "Taupo",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0432",
        Branch_Information: "Tauranga Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0436",
        Branch_Information: "Morrinsville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0440",
        Branch_Information: "Te Awamutu",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0444",
        Branch_Information: "Te Kauwhata",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0448",
        Branch_Information: "Te Kuiti",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0452",
        Branch_Information: "Te Puke",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0454",
        Branch_Information: "BNZ The Base",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0456",
        Branch_Information: "Thames",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0464",
        Branch_Information: "Tokoroa",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0466",
        Branch_Information: "Cameron Road Banking Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0468",
        Branch_Information: "Tuakau",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0470",
        Branch_Information: "Turangi",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0472",
        Branch_Information: "CAMERON ROAD BANKING CENTRE",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0476",
        Branch_Information: "Waiuku",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0478",
        Branch_Information: "Trust Bank Auckland/BNZ",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0480",
        Branch_Information: "Warkworth Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0484",
        Branch_Information: "Wellsford",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0488",
        Branch_Information: "Whakatane",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0492",
        Branch_Information: "Whangarei",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0494",
        Branch_Information: "Whangarei Central",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0496",
        Branch_Information: "Whitianga",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0499",
        Branch_Information: "Gis Atm Control",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0500",
        Branch_Information: "Wellington",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0506",
        Branch_Information: "222 Lambton Quay",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0512",
        Branch_Information: "Courtenay Place",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0520",
        Branch_Information: "Kilbirnie Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0524",
        Branch_Information: "Johnsonville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0528",
        Branch_Information: "Lower Hutt",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0534",
        Branch_Information: "Mayfair",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0536",
        Branch_Information: "North End",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0540",
        Branch_Information: "Naenae",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0544",
        Branch_Information: "Petone",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0548",
        Branch_Information: "Porirua",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0551",
        Branch_Information: "BNZ International Services Wellington",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0552",
        Branch_Information: "BNZ Porirua",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0554",
        Branch_Information: "Reserve Bank",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0555",
        Branch_Information: "International Service Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0560",
        Branch_Information: "50 Manners Street Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0562",
        Branch_Information: "Personal Banking Office",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0563",
        Branch_Information: "Gresham Plaza",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0568",
        Branch_Information: "Courtenay Place",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0570",
        Branch_Information: "Wainuiomata",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0573",
        Branch_Information: "BNZ Technology Test Branch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0576",
        Branch_Information: "Wellington South",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0585",
        Branch_Information: "Cathedral",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0590",
        Branch_Information: "Training Centre Branch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0591",
        Branch_Information: "Waikanae",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0600",
        Branch_Information: "Blenheim",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0602",
        Branch_Information: "New Plymouth East",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0604",
        Branch_Information: "Bulls",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0608",
        Branch_Information: "Carterton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0610",
        Branch_Information: "Waterloo Road",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0612",
        Branch_Information: "Dannevirke",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0620",
        Branch_Information: "Eltham",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0624",
        Branch_Information: "Featherston",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0628",
        Branch_Information: "Feilding",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0630",
        Branch_Information: "Fitzherbert Avenue",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0632",
        Branch_Information: "Foxton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0636",
        Branch_Information: "Gisborne",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0640",
        Branch_Information: "Greytown",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0644",
        Branch_Information: "Hastings",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0648",
        Branch_Information: "Hawera Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0652",
        Branch_Information: "Hunterville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0655",
        Branch_Information: "Havelock North",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0656",
        Branch_Information: "Inglewood",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0659",
        Branch_Information: "International Transfers Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0668",
        Branch_Information: "Levin",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0672",
        Branch_Information: "Manaia",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0673",
        Branch_Information: "Wanaka",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0680",
        Branch_Information: "Martinborough",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0684",
        Branch_Information: "BNZ Feilding",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0686",
        Branch_Information: "Lower Emerson Street",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0688",
        Branch_Information: "Masterton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0692",
        Branch_Information: "Motueka",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0700",
        Branch_Information: "Napier",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0704",
        Branch_Information: "Nelson",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0708",
        Branch_Information: "New Plymouth",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0712",
        Branch_Information: "Whanganui",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0716",
        Branch_Information: "Opunake",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0719",
        Branch_Information: "Terrace End",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0720",
        Branch_Information: "Otaki",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0724",
        Branch_Information: "Dannevirke",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0727",
        Branch_Information: "Palmerston North",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0733",
        Branch_Information: "Paraparaumu",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0740",
        Branch_Information: "Blenheim",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0741",
        Branch_Information: "Stortford Lodge",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0747",
        Branch_Information: "Richmond",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0756",
        Branch_Information: "Hawera",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0760",
        Branch_Information: "Taihape",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0764",
        Branch_Information: "Richmond",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0766",
        Branch_Information: "Taradale",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0772",
        Branch_Information: "Upper Hutt",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0776",
        Branch_Information: "Waipawa",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0780",
        Branch_Information: "Waipukurau",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0784",
        Branch_Information: "Gisborne",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0788",
        Branch_Information: "Waitara",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0792",
        Branch_Information: "Wanganui",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0796",
        Branch_Information: "Woodville",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0800",
        Branch_Information: "Cashel & Fitzgerald",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0808",
        Branch_Information: "Christchurch International Services",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0810",
        Branch_Information: "Hornby",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0816",
        Branch_Information: "Papanui",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0820",
        Branch_Information: "Riccarton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0828",
        Branch_Information: "Sydenham",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0832",
        Branch_Information: "Leeston",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0836",
        Branch_Information: "Ashburton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0840",
        Branch_Information: "Geraldine",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0842",
        Branch_Information: "BNZ Ferrymead",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0844",
        Branch_Information: "Greymouth",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0848",
        Branch_Information: "Hokitika",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0852",
        Branch_Information: "Kaiapoi",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0856",
        Branch_Information: "Kaikoura",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0858",
        Branch_Information: "BNZ International Services Christchurch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0860",
        Branch_Information: "Leeston",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0863",
        Branch_Information: "New Brighton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0864",
        Branch_Information: "Ferrymead Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0865",
        Branch_Information: "Armagh Street",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0868",
        Branch_Information: "Ashburton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0871",
        Branch_Information: "City South",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0874",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0875",
        Branch_Information: "University of Canterbury",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0876",
        Branch_Information: "Rangiora",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0880",
        Branch_Information: "Greymouth",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0884",
        Branch_Information: "Temuka",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0888",
        Branch_Information: "Timaru",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0892",
        Branch_Information: "Timaru",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0896",
        Branch_Information: "Greymouth",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0900",
        Branch_Information: "Dunedin",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0908",
        Branch_Information: "Dunedin North",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0910",
        Branch_Information: "South Dunedin",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0912",
        Branch_Information: "Dunedin",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0916",
        Branch_Information: "Alexandra",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0918",
        Branch_Information: "Gore",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0920",
        Branch_Information: "Cromwell",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0922",
        Branch_Information: "Gore",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0924",
        Branch_Information: "Invercargill",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0929",
        Branch_Information: "University Of Otago",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0930",
        Branch_Information: "Lumsden",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0935",
        Branch_Information: "Milton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0938",
        Branch_Information: "Mosgiel",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0940",
        Branch_Information: "Oamaru",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0944",
        Branch_Information: "Otautau",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0946",
        Branch_Information: "Palmerston",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0948",
        Branch_Information: "Queenstown",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0950",
        Branch_Information: "Ranfurly",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0953",
        Branch_Information: "Roxburgh",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0957",
        Branch_Information: "Winton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0959",
        Branch_Information: "Wyndham",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0965",
        Branch_Information: "Te Anau",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0975",
        Branch_Information: "Treasury Operations",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0985",
        Branch_Information: "BNZ Head Office",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0987",
        Branch_Information: "Credit Cards",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0989",
        Branch_Information: "Corporate H/O",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0993",
        Branch_Information: "Auckland Clearings",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "0995",
        Branch_Information: "Wellington Clearings",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1201",
        Branch_Information: "Christchurch Proof Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1202",
        Branch_Information: "Transaction Processing Centre Wellington",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1203",
        Branch_Information: "ReSTART",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1204",
        Branch_Information: "BNZ Small Business Hub",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1206",
        Branch_Information: "Lincoln North",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1207",
        Branch_Information: "Auckland Cheque Post",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1209",
        Branch_Information: "BNZ Partners Support Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1210",
        Branch_Information: "Greerton",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1211",
        Branch_Information: "Rfs Operations",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1212",
        Branch_Information: "Customer Account Services Wellington",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1213",
        Branch_Information: "Newmarket Mall",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1214",
        Branch_Information: "Auckland Banking Zone Office",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1215",
        Branch_Information: "Corporate Banking Wellington",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1216",
        Branch_Information: "Meadowlands",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1217",
        Branch_Information: "Lending Services Auckland",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1218",
        Branch_Information: "Corporate Banking South Island",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1219",
        Branch_Information: "Customer Account Services Auckland",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1220",
        Branch_Information: "Broadway",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1221",
        Branch_Information: "Parkroyal",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1222",
        Branch_Information: "DMS Auckland",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1223",
        Branch_Information: "Telebusiness Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1225",
        Branch_Information: "Auckland International Airport Agency",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1226",
        Branch_Information: "Direct Banking",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1227",
        Branch_Information: "Macquarie Investment Management Limited",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1228",
        Branch_Information: "Mortgage Distribution Fund",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1229",
        Branch_Information: "National Australia Bank Integration Br.",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1230",
        Branch_Information: "Sky City Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1231",
        Branch_Information: "Massey University",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1232",
        Branch_Information: "Global Banking",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1233",
        Branch_Information: "Direct Banking - Wellington",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1234",
        Branch_Information: "St Lukes",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1235",
        Branch_Information: "Christchurch International Airport",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1236",
        Branch_Information: "Hamilton Cash Services",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1237",
        Branch_Information: "Wellington Cash Services",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1238",
        Branch_Information: "Christchurch Cash Services",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1239",
        Branch_Information: "Auckland Call Centre",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1240",
        Branch_Information: "BNZ Broker Hub",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1241",
        Branch_Information: "Kookmin Bank",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1242",
        Branch_Information: "The Cooperative Bank 1",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1243",
        Branch_Information: "PC Business Banking",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1244",
        Branch_Information: "Albany",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1245",
        Branch_Information: "The Cooperative Bank 4",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1246",
        Branch_Information: "The Cooperative Bank 2",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1247",
        Branch_Information: "The Cooperative Bank 5",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1248",
        Branch_Information: "The Cooperative Bank 3",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1249",
        Branch_Information: "The Cooperative Bank 6",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1250",
        Branch_Information: "Wellington Imaging",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1251",
        Branch_Information: "Auckland Imaging",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1252",
        Branch_Information: "Tower",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1253",
        Branch_Information: "The Palms Mall",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1254",
        Branch_Information: "Whangamata",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1255",
        Branch_Information: "PC Internet Banking",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1256",
        Branch_Information: "Botany",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1257",
        Branch_Information: "80 Queen Street",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1258",
        Branch_Information: "Bayfair",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1259",
        Branch_Information: "BNZ Barrington Mall",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1260",
        Branch_Information: "Glenfield",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1261",
        Branch_Information: "BNZ Direct",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1262",
        Branch_Information: "Link Drive",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1263",
        Branch_Information: "Botany",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1264",
        Branch_Information: "BNZ Parnell",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1265",
        Branch_Information: "Whakatipu",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1266",
        Branch_Information: "Rolleston",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1267",
        Branch_Information: "BNZ Parkside",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1268",
        Branch_Information: "BNZ Christchurch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1269",
        Branch_Information: "BNZ Harbour Quays",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1271",
        Branch_Information: "Awhi Credit Union",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1272",
        Branch_Information: "The Base",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1273",
        Branch_Information: "Bank of Baroda",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1274",
        Branch_Information: "BNZ Connect",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1275",
        Branch_Information: "Sylvia Park Store",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1276",
        Branch_Information: "Commercial Bay",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1278",
        Branch_Information: "Russley",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1280",
        Branch_Information: "Booster Financial Services Ltd",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1281",
        Branch_Information: "Afterpay Ltd",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1283",
        Branch_Information: "Access Prepaid Worldwide CPP",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1284",
        Branch_Information: "Access Prepaid Worldwide - Qantas Cash NZ",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1285",
        Branch_Information: "NorthWest",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1286",
        Branch_Information: "BNZ Institutional Banking",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1289",
        Branch_Information: "Transferwise Limited",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1290",
        Branch_Information: "Transferwise Limited",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1291",
        Branch_Information: "Transferwise Ltd",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1292",
        Branch_Information: "Transferwise Limited",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1294",
        Branch_Information: "Waddle Loans Ltd",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1295",
        Branch_Information: "Toll Networks (NZ) Ltd",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1296",
        Branch_Information: "Toll Carriers Ltd",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "1298",
        Branch_Information: "Whangaparaoa",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2025",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2026",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2027",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2028",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2029",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2030",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2031",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2032",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2033",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2034",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2035",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2036",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2037",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2038",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2039",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2040",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2041",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New zealand",
        Branch_Number: "2042",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2043",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2044",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2045",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2046",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2047",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2048",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2049",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2050",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2051",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2052",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2053",
        Branch_Information: "BNZ Account",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2054",
        Branch_Information: "BNZ Account Test Branch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "2055",
        Branch_Information: "BNZ Account Training Branch",
        Bank_Number: "02",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0031",
        Branch_Information: "Auckland Clearings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0032",
        Branch_Information: "Auckland Clearings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0043",
        Branch_Information: "Wellington Clearings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0044",
        Branch_Information: "Wellington Clearings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0047",
        Branch_Information: "Westpac Head Office",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0048",
        Branch_Information: "Westpac Savings Bank",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0049",
        Branch_Information: "NZ Government Branch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0059",
        Branch_Information: "General Managers Office",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0060",
        Branch_Information: "ZMO",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0062",
        Branch_Information: "GMO Relief Staff",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0065",
        Branch_Information: "WBC Life NZ Limited",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0072",
        Branch_Information: "Westpac Travel",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0099",
        Branch_Information: "Card Services",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0104",
        Branch_Information: "79 Queen Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0105",
        Branch_Information: "Auckland",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0109",
        Branch_Information: "LynnMall",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0114",
        Branch_Information: "Birkenhead",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0116",
        Branch_Information: "Birkenhead",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0118",
        Branch_Information: "LynnMall",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0123",
        Branch_Information: "Browns Bay",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0127",
        Branch_Information: "Bankcard Department",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0132",
        Branch_Information: "Downtown Auckland",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0133",
        Branch_Information: "Auckland",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0138",
        Branch_Information: "Shortland Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0140",
        Branch_Information: "First 100 Days",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0146",
        Branch_Information: "Henderson",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0149",
        Branch_Information: "St Johns",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0150",
        Branch_Information: "Howick",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0155",
        Branch_Information: "Henderson",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0156",
        Branch_Information: "Westpac Lincoln Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0162",
        Branch_Information: "79 Queen Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0166",
        Branch_Information: "Howick",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0173",
        Branch_Information: "Papatoetoe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0174",
        Branch_Information: "Hunters Corner Papatoetoe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0175",
        Branch_Information: "Mid City",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0179",
        Branch_Information: "Mt Roskill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0180",
        Branch_Information: "Browns Bay",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0181",
        Branch_Information: "Milford",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0187",
        Branch_Information: "LynnMall",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0189",
        Branch_Information: "3073 Great North Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0195",
        Branch_Information: "Newmarket",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0196",
        Branch_Information: "Newmarket",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0198",
        Branch_Information: "DPS Palmerston North",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0203",
        Branch_Information: "Mid City",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0206",
        Branch_Information: "Botany at The Hub",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0207",
        Branch_Information: "Manukau City",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0211",
        Branch_Information: "Onehunga",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0212",
        Branch_Information: "Onehunga Mall",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0219",
        Branch_Information: "Otahuhu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0220",
        Branch_Information: "Otahuhu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0227",
        Branch_Information: "Panmure",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0228",
        Branch_Information: "Ponsonby",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0231",
        Branch_Information: "Papatoetoe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0239",
        Branch_Information: "Newmarket",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0243",
        Branch_Information: "St Johns",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0250",
        Branch_Information: "C and I B Head Office",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0251",
        Branch_Information: "Government Business",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0252",
        Branch_Information: "79 Queen Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0253",
        Branch_Information: "Westpac Tower Branch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0255",
        Branch_Information: "Ponsonby",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0259",
        Branch_Information: "Remuera",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0263",
        Branch_Information: "St Heliers",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0267",
        Branch_Information: "St Johns",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0268",
        Branch_Information: "Southdown Dcbc",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0269",
        Branch_Information: "South Auckland Dcbc",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0275",
        Branch_Information: "Takapuna",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0276",
        Branch_Information: "Takapuna",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0282",
        Branch_Information: "79 Queen Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0283",
        Branch_Information: "Newmarket",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0285",
        Branch_Information: "Glenfield",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0291",
        Branch_Information: "79 Queen Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0292",
        Branch_Information: "Westpac Tower Branch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0296",
        Branch_Information: "Mid City",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0303",
        Branch_Information: "Cambridge",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0305",
        Branch_Information: "Hamilton North",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0306",
        Branch_Information: "Hamilton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0314",
        Branch_Information: "Frankton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0318",
        Branch_Information: "Hamilton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0319",
        Branch_Information: "Hamilton 426 Victoria St",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0326",
        Branch_Information: "Waikato Savings Bank",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0334",
        Branch_Information: "Kaikohe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0339",
        Branch_Information: "Kaitaia",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0345",
        Branch_Information: "Whangarei",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0346",
        Branch_Information: "Kaikohe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0347",
        Branch_Information: "Orewa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0351",
        Branch_Information: "Kerikeri",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0355",
        Branch_Information: "Manurewa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0356",
        Branch_Information: "Manurewa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0363",
        Branch_Information: "Matamata",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0366",
        Branch_Information: "Hamilton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0371",
        Branch_Information: "Morrinsville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0374",
        Branch_Information: "Mt Maunganui",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0385",
        Branch_Information: "Papakura",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0389",
        Branch_Information: "Silverdale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0394",
        Branch_Information: "Paihia",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0399",
        Branch_Information: "Papakura",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0406",
        Branch_Information: "Pukekohe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0407",
        Branch_Information: "127 King Street Pukekohe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0415",
        Branch_Information: "Rotorua Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0417",
        Branch_Information: "Amohia Street Rotorua",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0418",
        Branch_Information: "Matamata",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0419",
        Branch_Information: "Silverdale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0423",
        Branch_Information: "Papakura",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0426",
        Branch_Information: "Taumarunui",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0430",
        Branch_Information: "Taupo",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0431",
        Branch_Information: "Heu Heu Street Taupo",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0435",
        Branch_Information: "Cameron Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0442",
        Branch_Information: "Te Awamutu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0443",
        Branch_Information: "Te Awamutu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0445",
        Branch_Information: "Cameron Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0446",
        Branch_Information: "Grey Street Tauranga",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0449",
        Branch_Information: "Te Awamutu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0458",
        Branch_Information: "Thames",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0463",
        Branch_Information: "Tokoroa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0474",
        Branch_Information: "Te Puke",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0481",
        Branch_Information: "Warkworth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0485",
        Branch_Information: "Warkworth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0490",
        Branch_Information: "Whakatane",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0497",
        Branch_Information: "Whangarei",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0498",
        Branch_Information: "Whangarei",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0502",
        Branch_Information: "318 Lambton Quay",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0503",
        Branch_Information: "Wellington",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0510",
        Branch_Information: "Courtenay Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0511",
        Branch_Information: "Ghuznee Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0515",
        Branch_Information: "Courtenay Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0518",
        Branch_Information: "North End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0521",
        Branch_Information: "Kilbirnie",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0522",
        Branch_Information: "SmartStation - Queens Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0525",
        Branch_Information: "Johnsonville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0531",
        Branch_Information: "Lower Hutt",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0532",
        Branch_Information: "Lower Hutt",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0538",
        Branch_Information: "Personnel Dept Head Off",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0539",
        Branch_Information: "North End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0543",
        Branch_Information: "Petone",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0547",
        Branch_Information: "Porirua",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0550",
        Branch_Information: "Porirua",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0558",
        Branch_Information: "Courtenay Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0559",
        Branch_Information: "Ghuznee Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0566",
        Branch_Information: "North End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0567",
        Branch_Information: "Willams Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0572",
        Branch_Information: "Centreville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0578",
        Branch_Information: "Kilbirnie",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0579",
        Branch_Information: "Wellington IPC",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0584",
        Branch_Information: "318 Lambton Quay",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0587",
        Branch_Information: "Systems Operations",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0588",
        Branch_Information: "International Business",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0595",
        Branch_Information: "SBO Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0597",
        Branch_Information: "Northern Test Branch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0599",
        Branch_Information: "Blenheim",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0605",
        Branch_Information: "Feilding",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0609",
        Branch_Information: "Masterton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0614",
        Branch_Information: "Dannevirke",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0615",
        Branch_Information: "Dannevirke",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0617",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0618",
        Branch_Information: "Gisborne",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0619",
        Branch_Information: "Stratford",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0626",
        Branch_Information: "Feilding",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0627",
        Branch_Information: "Feilding",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0631",
        Branch_Information: "Taradale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0633",
        Branch_Information: "Levin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0638",
        Branch_Information: "Gisborne",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0639",
        Branch_Information: "Gisborne",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0642",
        Branch_Information: "Hastings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0643",
        Branch_Information: "Queen Street Hastings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0647",
        Branch_Information: "Hawera",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0654",
        Branch_Information: "Hawera",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0657",
        Branch_Information: "New Plymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0658",
        Branch_Information: "Havelock North",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0661",
        Branch_Information: "Kaponga",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0667",
        Branch_Information: "Levin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0670",
        Branch_Information: "Levin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0674",
        Branch_Information: "Rangiora",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0675",
        Branch_Information: "Queenstown",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0683",
        Branch_Information: "Feilding",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0687",
        Branch_Information: "Masterton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0690",
        Branch_Information: "Masterton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0693",
        Branch_Information: "Motueka",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0698",
        Branch_Information: "Napier",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0699",
        Branch_Information: "Napier",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0703",
        Branch_Information: "Nelson",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0706",
        Branch_Information: "Nelson",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0710",
        Branch_Information: "Broadway, Palmerston North",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0711",
        Branch_Information: "New Plymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0713",
        Branch_Information: "New Plymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0715",
        Branch_Information: "Hawera",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0718",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0722",
        Branch_Information: "Armagh Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0725",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0726",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0728",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0732",
        Branch_Information: "Paraparaumu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0734",
        Branch_Information: "Paraparaumu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0737",
        Branch_Information: "Hawera",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0739",
        Branch_Information: "Hastings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0742",
        Branch_Information: "Waverley",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0749",
        Branch_Information: "Gisborne",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0750",
        Branch_Information: "243 Queen St Richmond",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0751",
        Branch_Information: "Richmond",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0758",
        Branch_Information: "Stratford",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0762",
        Branch_Information: "Marton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0767",
        Branch_Information: "Hornby",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0774",
        Branch_Information: "Upper Hutt",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0775",
        Branch_Information: "Upper Hutt",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0779",
        Branch_Information: "Waipukurau",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0783",
        Branch_Information: "Wairoa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0785",
        Branch_Information: "Gisborne",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0786",
        Branch_Information: "New Plymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0791",
        Branch_Information: "Wanganui",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0794",
        Branch_Information: "St Johns Wanganui",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0799",
        Branch_Information: "93 Armagh Street ChCh",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0802",
        Branch_Information: "Christchurch Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0803",
        Branch_Information: "Christchurch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0809",
        Branch_Information: "Windsor",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0813",
        Branch_Information: "Courier Banking CHCH",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0814",
        Branch_Information: "The Palms",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0818",
        Branch_Information: "Papanui",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0823",
        Branch_Information: "Riccarton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0824",
        Branch_Information: "126 Riccarton Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0826",
        Branch_Information: "Merivale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0827",
        Branch_Information: "Sydenham",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0830",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0835",
        Branch_Information: "Ashburton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0838",
        Branch_Information: "Ashburton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0839",
        Branch_Information: "Geraldine",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0846",
        Branch_Information: "Greymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0847",
        Branch_Information: "Greymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0850",
        Branch_Information: "Greymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0854",
        Branch_Information: "Christchurch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0855",
        Branch_Information: "Merivale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0857",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0859",
        Branch_Information: "Eastgate",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0861",
        Branch_Information: "High Street Christchurch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0866",
        Branch_Information: "Christchurch Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0873",
        Branch_Information: "NZIO Auckland",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0881",
        Branch_Information: "South Dunedin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0883",
        Branch_Information: "Dunedin North",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0887",
        Branch_Information: "Timaru",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0890",
        Branch_Information: "Timaru South",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0895",
        Branch_Information: "Westland Savings Bank",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0897",
        Branch_Information: "Westport",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0898",
        Branch_Information: "Westport",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0903",
        Branch_Information: "Moray Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0904",
        Branch_Information: "Dunedin 169 Princes St",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0905",
        Branch_Information: "Moray Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0915",
        Branch_Information: "Gore",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0931",
        Branch_Information: "Invercargill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0933",
        Branch_Information: "Invercargill 34 Dee St",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0934",
        Branch_Information: "Gore",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0937",
        Branch_Information: "Oamaru",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0947",
        Branch_Information: "Oamaru",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0951",
        Branch_Information: "Alexandra",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0960",
        Branch_Information: "Windsor",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0962",
        Branch_Information: "Gore",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0978",
        Branch_Information: "Stationery Department",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "0990",
        Branch_Information: "Card Services",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1300",
        Branch_Information: "Core Business Systems",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1301",
        Branch_Information: "Whangarei Proof Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1302",
        Branch_Information: "Gotham City",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1303",
        Branch_Information: "NZ Operations, Int Svs",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1304",
        Branch_Information: "Client Services",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1305",
        Branch_Information: "Consumer Network Support",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1306",
        Branch_Information: "Christchurch IPC",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1307",
        Branch_Information: "Financial Services Proces",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1308",
        Branch_Information: "Onehunga",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1309",
        Branch_Information: "IBC - Napier",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1310",
        Branch_Information: "Credit Support",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1311",
        Branch_Information: "Dunedin IPC",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1312",
        Branch_Information: "NZIO Wellington",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1313",
        Branch_Information: "Manukau City I P C",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1314",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1315",
        Branch_Information: "IBC - Christchurch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1316",
        Branch_Information: "Courier Banking Auckland",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1317",
        Branch_Information: "Auckland Support Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1318",
        Branch_Information: "Te Awa The Base",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1319",
        Branch_Information: "North Shore IPC",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1320",
        Branch_Information: "Howick",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1321",
        Branch_Information: "Operations Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1322",
        Branch_Information: "Albany",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1323",
        Branch_Information: "Trust Bank Int Support",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1324",
        Branch_Information: "Courier Banking-Hamilton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1325",
        Branch_Information: "DPS Wellington - Courier",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1326",
        Branch_Information: "DPS Auckland - Courier",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1327",
        Branch_Information: "Fraud & Security Services",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1328",
        Branch_Information: "Albany Business",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1329",
        Branch_Information: "Westpac Terminals",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1330",
        Branch_Information: "Papamoa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1331",
        Branch_Information: "Collections",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1332",
        Branch_Information: "Auckland Business Mail",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1333",
        Branch_Information: "Gainskill Avenue",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1334",
        Branch_Information: "Westpac 9",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1335",
        Branch_Information: "CRG-Credit Restructuring",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1340",
        Branch_Information: "Warehouse Fin Serv Ltd",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1341",
        Branch_Information: "Transaction Banking 11(A)",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1342",
        Branch_Information: "Retail Lending CoE",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1350",
        Branch_Information: "Bank Of Tokyo-Mitsubishi",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1351",
        Branch_Information: "Transaction Banking 1",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1352",
        Branch_Information: "Transaction Banking 2",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1353",
        Branch_Information: "Transaction Banking 3",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1354",
        Branch_Information: "Transaction Banking 4",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1355",
        Branch_Information: "Transaction Banking 5",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1356",
        Branch_Information: "Transaction Banking 6",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1357",
        Branch_Information: "Transaction Banking 7",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1358",
        Branch_Information: "Transaction Banking 8",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1359",
        Branch_Information: "Transaction Banking 9",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1360",
        Branch_Information: "Transaction Banking 10",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1361",
        Branch_Information: "Transaction Banking 11",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1362",
        Branch_Information: "Transaction Banking 12",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1363",
        Branch_Information: "Transaction Banking 13",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1364",
        Branch_Information: "Transaction Banking 14",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1365",
        Branch_Information: "Transaction Banking 15",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1366",
        Branch_Information: "Transaction Banking 16",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1367",
        Branch_Information: "Transaction Banking 17",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1368",
        Branch_Information: "Transaction Banking 18",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1369",
        Branch_Information: "Transaction Banking 19",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1370",
        Branch_Information: "Auckland Call Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1371",
        Branch_Information: "Christchurch Call Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1372",
        Branch_Information: "Operations Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1382",
        Branch_Information: "Wellington Phone Assist",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1383",
        Branch_Information: "Direct Debit Dish Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1384",
        Branch_Information: "Courier Banking-Wellington",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1385",
        Branch_Information: "Loan Assessment Centre",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1386",
        Branch_Information: "Business Direct Wgtn",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1387",
        Branch_Information: "Agribusiness Morrinsville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1388",
        Branch_Information: "Westpac Direct Auckland",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1389",
        Branch_Information: "Westpac Direct ChCh",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1390",
        Branch_Information: "Botany at The Hub",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1391",
        Branch_Information: "Glenfield",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1392",
        Branch_Information: "St Johns",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1393",
        Branch_Information: "Huapai",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1394",
        Branch_Information: "Newmarket",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1395",
        Branch_Information: "Digital Branch 2",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1396",
        Branch_Information: "Mangere",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1397",
        Branch_Information: "Takanini",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1398",
        Branch_Information: "Highland Park",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1399",
        Branch_Information: "Digital Branch",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1500",
        Branch_Information: "St Lukes",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1501",
        Branch_Information: "Dargaville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1502",
        Branch_Information: "Silverdale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1503",
        Branch_Information: "NorthWest",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1504",
        Branch_Information: "Albany",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1505",
        Branch_Information: "Westpac Lincoln Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1506",
        Branch_Information: "Manukau City Mall",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1507",
        Branch_Information: "Papatoetoe",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1508",
        Branch_Information: "Pakuranga",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1509",
        Branch_Information: "Britomart",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1510",
        Branch_Information: "Takapuna",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1512",
        Branch_Information: "Ponsonby",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1513",
        Branch_Information: "Mt Roskill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1514",
        Branch_Information: "Takapuna",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1515",
        Branch_Information: "Glenfield",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1516",
        Branch_Information: "Browns Bay Sc",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1517",
        Branch_Information: "Napier",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1518",
        Branch_Information: "Hastings",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1519",
        Branch_Information: "Plaza",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1520",
        Branch_Information: "Eastgate",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1521",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1522",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1523",
        Branch_Information: "Terrace End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1524",
        Branch_Information: "Taihape",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1525",
        Branch_Information: "Taihape",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1526",
        Branch_Information: "Queenstown Junction",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1527",
        Branch_Information: "79 Queen Street",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1528",
        Branch_Information: "Sylvia Park",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1529",
        Branch_Information: "Botany Junction",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1530",
        Branch_Information: "SBO Centre 2",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1531",
        Branch_Information: "Paraparaumu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1532",
        Branch_Information: "Levin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1533",
        Branch_Information: "Porirua",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1534",
        Branch_Information: "Johnsonville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1535",
        Branch_Information: "Lower Hutt",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1536",
        Branch_Information: "Johnsonville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1537",
        Branch_Information: "North End",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1538",
        Branch_Information: "Ruakaka",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1539",
        Branch_Information: "Westpac 4",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1540",
        Branch_Information: "318 Lambton Quay",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1544",
        Branch_Information: "Whakatane",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1545",
        Branch_Information: "Rotorua Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1546",
        Branch_Information: "Whakatane",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1547",
        Branch_Information: "Bayfair",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1548",
        Branch_Information: "Cameron Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1549",
        Branch_Information: "Tauranga Crossing",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1550",
        Branch_Information: "Katikati",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1551",
        Branch_Information: "Bayfair",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1552",
        Branch_Information: "Rotorua Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1553",
        Branch_Information: "Rolleston",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1555",
        Branch_Information: "Hamilton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1556",
        Branch_Information: "Chartwell",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1557",
        Branch_Information: "Chartwell",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1558",
        Branch_Information: "Frankton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1559",
        Branch_Information: "Chartwell",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1560",
        Branch_Information: "Hamilton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1561",
        Branch_Information: "Chartwell",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1562",
        Branch_Information: "Te Awamutu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1563",
        Branch_Information: "Frankton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1564",
        Branch_Information: "Te Awamutu",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1565",
        Branch_Information: "Britomart Migrant",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1566",
        Branch_Information: "New Plymouth",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1567",
        Branch_Information: "Wanganui",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1568",
        Branch_Information: "Cambridge",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1570",
        Branch_Information: "Te Awa The Base",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1571",
        Branch_Information: "Te Awa The Base",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1572",
        Branch_Information: "Paeroa",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1573",
        Branch_Information: "Morrinsville",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1574",
        Branch_Information: "Te Awa The Base",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1575",
        Branch_Information: "Waihi",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1576",
        Branch_Information: "Waihi",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1577",
        Branch_Information: "Waihi",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1578",
        Branch_Information: "Whitianga",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1582",
        Branch_Information: "Amberley",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1583",
        Branch_Information: "Riccarton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1584",
        Branch_Information: "Halswell",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1585",
        Branch_Information: "Kaiapoi",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1586",
        Branch_Information: "Kaikoura",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1587",
        Branch_Information: "Rolleston",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1588",
        Branch_Information: "Rolleston",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1590",
        Branch_Information: "Ferrymead",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1591",
        Branch_Information: "Eastgate",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1592",
        Branch_Information: "Christchurch Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1593",
        Branch_Information: "Christchurch Central",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1594",
        Branch_Information: "Barrington",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1595",
        Branch_Information: "The Palms",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1596",
        Branch_Information: "Ferrymead",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1597",
        Branch_Information: "The Palms",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1598",
        Branch_Information: "Barrington",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1599",
        Branch_Information: "Ferrymead",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1700",
        Branch_Information: "The Palms",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1701",
        Branch_Information: "Auckland Airport",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1702",
        Branch_Information: "Merivale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1703",
        Branch_Information: "Barrington",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1704",
        Branch_Information: "Merivale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1705",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1706",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1707",
        Branch_Information: "Merivale",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1709",
        Branch_Information: "Richmond",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1710",
        Branch_Information: "Blenheim",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1711",
        Branch_Information: "Motueka",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1714",
        Branch_Information: "Northtown",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1715",
        Branch_Information: "Northtown",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1716",
        Branch_Information: "Geraldine",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1717",
        Branch_Information: "Timaru",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1718",
        Branch_Information: "Timaru",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1719",
        Branch_Information: "Ashburton",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1720",
        Branch_Information: "Cameron Road",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1725",
        Branch_Information: "Mosgiel",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1726",
        Branch_Information: "Dunedin North",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1727",
        Branch_Information: "South Dunedin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1728",
        Branch_Information: "Moray Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1729",
        Branch_Information: "South Dunedin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1730",
        Branch_Information: "NorthWest",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1732",
        Branch_Information: "South Dunedin",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1733",
        Branch_Information: "Alexandra",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1734",
        Branch_Information: "Balclutha",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1735",
        Branch_Information: "Alexandra",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1736",
        Branch_Information: "Balclutha",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1737",
        Branch_Information: "Moray Place",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1738",
        Branch_Information: "Alexandra",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1739",
        Branch_Information: "Wanaka",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1742",
        Branch_Information: "Invercargill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1743",
        Branch_Information: "Invercargill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1744",
        Branch_Information: "Invercargill South",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1745",
        Branch_Information: "Windsor",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1746",
        Branch_Information: "Windsor",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1747",
        Branch_Information: "Gore",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1748",
        Branch_Information: "Windsor",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1749",
        Branch_Information: "Invercargill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1750",
        Branch_Information: "Invercargill",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1751",
        Branch_Information: "Transaction Banking 51",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1752",
        Branch_Information: "Transaction Banking 52",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1753",
        Branch_Information: "Transaction Banking 53",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1754",
        Branch_Information: "Transaction Banking 54",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1755",
        Branch_Information: "Transaction Banking 55",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1756",
        Branch_Information: "Transaction Banking 56",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1757",
        Branch_Information: "Transaction Banking 57",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1758",
        Branch_Information: "Transaction Banking 58",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1759",
        Branch_Information: "Transaction Banking 59",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1760",
        Branch_Information: "Transaction Banking 60",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1761",
        Branch_Information: "Transaction Banking 61",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1762",
        Branch_Information: "Transaction Banking 62",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1763",
        Branch_Information: "Transaction Banking 63",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1764",
        Branch_Information: "Transaction Banking 64",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1765",
        Branch_Information: "Transaction Banking 65",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1766",
        Branch_Information: "Transaction Banking 66",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1767",
        Branch_Information: "Transaction Banking 67",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1768",
        Branch_Information: "Transaction Banking 68",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1769",
        Branch_Information: "Transaction Banking 69",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1770",
        Branch_Information: "Transaction Banking 70",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1771",
        Branch_Information: "Transaction Banking 71",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1772",
        Branch_Information: "Transaction Banking 72",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1773",
        Branch_Information: "Transaction Banking 73",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1774",
        Branch_Information: "Transaction Banking 74",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1775",
        Branch_Information: "Transaction Banking 75",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1776",
        Branch_Information: "Transaction Banking 76",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1777",
        Branch_Information: "Transaction Banking 77",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1778",
        Branch_Information: "Transaction Banking 78",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1779",
        Branch_Information: "Transaction Banking 79",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1780",
        Branch_Information: "Transaction Banking 80",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1781",
        Branch_Information: "Transaction Banking 81",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1782",
        Branch_Information: "Transaction Banking 82",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1783",
        Branch_Information: "Transaction Banking 83",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1784",
        Branch_Information: "Transaction Banking 84",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1785",
        Branch_Information: "Transaction Banking 85",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1786",
        Branch_Information: "Transaction Banking 86",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1787",
        Branch_Information: "Transaction Banking 87",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1788",
        Branch_Information: "Transaction Banking 88",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1789",
        Branch_Information: "Transaction Banking 106",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1790",
        Branch_Information: "Transaction Banking 90",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1791",
        Branch_Information: "Transaction Banking 91",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1792",
        Branch_Information: "Transaction Banking 104",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1793",
        Branch_Information: "Transaction Banking 105",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1794",
        Branch_Information: "Transaction Banking 107",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1795",
        Branch_Information: "Transaction Banking 95",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1796",
        Branch_Information: "Transaction Banking 96",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1797",
        Branch_Information: "Transaction Banking 97",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1798",
        Branch_Information: "Transaction Banking 98",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1799",
        Branch_Information: "Transaction Banking 99",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1900",
        Branch_Information: "Transaction Banking 00",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1901",
        Branch_Information: "Transaction Banking 108",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1902",
        Branch_Information: "Transaction Banking 109",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1903",
        Branch_Information: "Transaction Banking 103",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1904",
        Branch_Information: "Transaction Banking 110",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1905",
        Branch_Information: "Transaction Banking 111",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1906",
        Branch_Information: "Transaction Banking 112",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1907",
        Branch_Information: "Transaction Banking 113",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1908",
        Branch_Information: "Transaction Banking 114",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1909",
        Branch_Information: "Transaction Banking 115",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1910",
        Branch_Information: "Transaction Banking 116",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1911",
        Branch_Information: "Transaction Banking 117",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1912",
        Branch_Information: "Transaction Banking 118",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1913",
        Branch_Information: "Transaction Banking 119",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1914",
        Branch_Information: "Transaction Banking 120",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1915",
        Branch_Information: "Transaction Banking 121",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1916",
        Branch_Information: "Transaction Banking 122",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1917",
        Branch_Information: "Transaction Banking 123",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1918",
        Branch_Information: "Transaction Banking 124",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "1919",
        Branch_Information: "Transaction Banking 125",
        Bank_Number: "03",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "7355",
        Branch_Information: "Branch On Demand",
        Bank_Number: "03",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2020",
        Branch_Information: "ANZ Institutional",
        Bank_Number: "04",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2021",
        Branch_Information: "ANZ Institutional",
        Bank_Number: "04",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2022",
        Branch_Information: "ANZ Institutional",
        Bank_Number: "04",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2023",
        Branch_Information: "ANZ Institutional",
        Bank_Number: "04",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2024",
        Branch_Information: "ANZ Institutional",
        Bank_Number: "04",
    },
    {
        Bank_Name: "China Construction Bank NZ Ltd",
        Branch_Number: "8884",
        Branch_Information: "Shortland Street",
        Bank_Number: "05",
    },
    {
        Bank_Name: "China Construction Bank NZ Ltd",
        Branch_Number: "8885",
        Branch_Information: "Shortland Street",
        Bank_Number: "05",
    },
    {
        Bank_Name: "China Construction Bank NZ Ltd",
        Branch_Number: "8886",
        Branch_Information: "Shortland Street",
        Bank_Number: "05",
    },
    {
        Bank_Name: "China Construction Bank NZ Ltd",
        Branch_Number: "8887",
        Branch_Information: "Shortland Street",
        Bank_Number: "05",
    },
    {
        Bank_Name: "China Construction Bank NZ Ltd",
        Branch_Number: "8888",
        Branch_Information: "Shortland Street",
        Bank_Number: "05",
    },
    {
        Bank_Name: "China Construction Bank NZ Ltd",
        Branch_Number: "8889",
        Branch_Information: "Shortland Street",
        Bank_Number: "05",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0006",
        Branch_Information: "Wholesale Division",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0023",
        Branch_Information: "Corporate Portfolio Management",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0024",
        Branch_Information: "Agri Central Branch",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0067",
        Branch_Information: "Institutional Banking",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0081",
        Branch_Information: "Te Kuiti",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0082",
        Branch_Information: "Sylvia Park",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0101",
        Branch_Information: "Auckland",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0103",
        Branch_Information: "Lending Services",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0111",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0115",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0122",
        Branch_Information: "Browns Bay",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0134",
        Branch_Information: "Transaction Services",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0145",
        Branch_Information: "Dominion Road",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0153",
        Branch_Information: "Henderson",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0158",
        Branch_Information: "Auckland University",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0163",
        Branch_Information: "Auckland Corporate and Commercial Banking",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0164",
        Branch_Information: "Retail Debt Management (Personal)",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0169",
        Branch_Information: "Uxbridge Road",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0172",
        Branch_Information: "Hunters Corner",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0177",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0185",
        Branch_Information: "New Lynn",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0188",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0193",
        Branch_Information: "Newmarket",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0197",
        Branch_Information: "Manukau Mall",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0199",
        Branch_Information: "Ponsonby",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0201",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0209",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0217",
        Branch_Information: "Otahuhu",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0222",
        Branch_Information: "Pakuranga",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0225",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0229",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0230",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0233",
        Branch_Information: "NBNZ Property Finance",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0237",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0241",
        Branch_Information: "Greenlane",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0254",
        Branch_Information: "Huapai",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0257",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0265",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0266",
        Branch_Information: "St Heliers",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0273",
        Branch_Information: "Takapuna",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0284",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0287",
        Branch_Information: "205 Queen Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0293",
        Branch_Information: "East Tamaki",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0294",
        Branch_Information: "Wairau Road",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0299",
        Branch_Information: "Katikati",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0301",
        Branch_Information: "Cambridge",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0309",
        Branch_Information: "Dargaville",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0313",
        Branch_Information: "Frankton",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0317",
        Branch_Information: "Hamilton",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0323",
        Branch_Information: "45 Queen Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0329",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0333",
        Branch_Information: "Kaikohe",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0335",
        Branch_Information: "Hamilton Agri",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0337",
        Branch_Information: "Kaitaia",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0350",
        Branch_Information: "Kerikeri",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0359",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0361",
        Branch_Information: "Matamata",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0365",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0369",
        Branch_Information: "Morrinsville",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0375",
        Branch_Information: "ANZ Finance",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0377",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0379",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0383",
        Branch_Information: "Orewa",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0393",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0397",
        Branch_Information: "Normanby Road",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0401",
        Branch_Information: "Papakura",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0405",
        Branch_Information: "Pukekohe",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0409",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0411",
        Branch_Information: "Direct Investments",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0413",
        Branch_Information: "Fenton Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0421",
        Branch_Information: "Constellation Drive",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0429",
        Branch_Information: "Taupo",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0433",
        Branch_Information: "Tauranga",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0437",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0441",
        Branch_Information: "Te Awamutu",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0453",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0457",
        Branch_Information: "Thames",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0459",
        Branch_Information: "The Base",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0465",
        Branch_Information: "Tokoroa",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0469",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0471",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0473",
        Branch_Information: "Waihi",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0477",
        Branch_Information: "Waiuku",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0479",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0483",
        Branch_Information: "Warkworth",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0489",
        Branch_Information: "Whakatane",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0491",
        Branch_Information: "Eleventh Avenue",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0493",
        Branch_Information: "Whangarei",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0501",
        Branch_Information: "Willis Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0507",
        Branch_Information: "Wellington Commercial",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0513",
        Branch_Information: "Courtenay Place",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0529",
        Branch_Information: "Lower Hutt",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0541",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0545",
        Branch_Information: "Petone",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0549",
        Branch_Information: "Porirua",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0556",
        Branch_Information: "Papamoa",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0561",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0565",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0569",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0574",
        Branch_Information: "Kilbirnie",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0575",
        Branch_Information: "Credit Assessment",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0577",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0580",
        Branch_Information: "ANZ Corporate Headquarters",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0581",
        Branch_Information: "Molesworth Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0582",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0583",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0589",
        Branch_Information: "Nat IBIS Testing",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0592",
        Branch_Information: "Waikanae",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0594",
        Branch_Information: "ANZ Private",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0596",
        Branch_Information: "Westgate",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0601",
        Branch_Information: "Blenheim",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0603",
        Branch_Information: "Chartwell",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0606",
        Branch_Information: "Victoria University",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0613",
        Branch_Information: "87 - 89 High Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0622",
        Branch_Information: "Lincoln North",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0629",
        Branch_Information: "Manchester Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0637",
        Branch_Information: "Gisborne",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0645",
        Branch_Information: "Hastings",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0649",
        Branch_Information: "Hawera",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0663",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0665",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0669",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0689",
        Branch_Information: "Masterton",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0701",
        Branch_Information: "Napier",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0705",
        Branch_Information: "Nelson",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0709",
        Branch_Information: "New Plymouth",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0729",
        Branch_Information: "Cuba and Rangitikei",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0730",
        Branch_Information: "Paraparaumu",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0738",
        Branch_Information: "Stortford Lodge",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0746",
        Branch_Information: "Palmerston North",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0757",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0765",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0773",
        Branch_Information: "Upper Hutt",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0781",
        Branch_Information: "Waipukurau",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0789",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0793",
        Branch_Information: "Wanganui",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0801",
        Branch_Information: "Christchurch",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0805",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0807",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0817",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0821",
        Branch_Information: "Riccarton Road",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0829",
        Branch_Information: "385 Colombo Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0831",
        Branch_Information: "Victoria Square",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0837",
        Branch_Information: "Ashburton",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0845",
        Branch_Information: "Greymouth North",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0849",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0851",
        Branch_Information: "Rolleston",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0869",
        Branch_Information: "Direct Business Banking",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0870",
        Branch_Information: "New Brighton",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0878",
        Branch_Information: "WestCity",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0879",
        Branch_Information: "The Palms Mall",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0889",
        Branch_Information: "Timaru",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0894",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0899",
        Branch_Information: "Trust Management",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0901",
        Branch_Information: "Dunedin",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0909",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0911",
        Branch_Information: "South Dunedin",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0917",
        Branch_Information: "Alexandra",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0919",
        Branch_Information: "Clyde Street",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0921",
        Branch_Information: "Cromwell",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0923",
        Branch_Information: "Gore",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0925",
        Branch_Information: "Invercargill",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0927",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0936",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0939",
        Branch_Information: "Mosgiel",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0941",
        Branch_Information: "Oamaru",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0942",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0943",
        Branch_Information: "Wanaka",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0949",
        Branch_Information: "Queenstown",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0954",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0956",
        Branch_Information: "Mid Corporate",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0958",
        Branch_Information: "Richmond",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0966",
        Branch_Information: "Twizel",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0968",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0977",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0983",
        Branch_Information: "ANZ Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0986",
        Branch_Information: "Card Operations",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0991",
        Branch_Information: "Retail",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0994",
        Branch_Information: "Nationwide Home Loans Ltd",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0996",
        Branch_Information: "Nat Wellington Clearings",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "0998",
        Branch_Information: "Albany Mall",
        Bank_Number: "06",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "1499",
        Branch_Information: "Trust Management",
        Bank_Number: "06",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6501",
        Branch_Information: "Auckland Proof Centre",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6502",
        Branch_Information: "International",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6503",
        Branch_Information: "Treasury",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6504",
        Branch_Information: "Central Processing",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6511",
        Branch_Information: "Whangarei",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6513",
        Branch_Information: "Browns Bay",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6514",
        Branch_Information: "Wairau Valley",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6515",
        Branch_Information: "Takapuna",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6517",
        Branch_Information: "Henderson",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6519",
        Branch_Information: "New Lynn",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6521",
        Branch_Information: "Strand Arcade",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6523",
        Branch_Information: "Grafton",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6525",
        Branch_Information: "Newmarket",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6529",
        Branch_Information: "Penrose",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6531",
        Branch_Information: "Panmure",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6533",
        Branch_Information: "Papatoetoe",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6535",
        Branch_Information: "Papakura",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6537",
        Branch_Information: "Manukau City",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6541",
        Branch_Information: "Hamilton",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6543",
        Branch_Information: "Tauranga",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6551",
        Branch_Information: "Rotorua",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6553",
        Branch_Information: "Taupo",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6555",
        Branch_Information: "Gisborne",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6557",
        Branch_Information: "New Plymouth",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6559",
        Branch_Information: "Napier",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6561",
        Branch_Information: "Hastings",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6563",
        Branch_Information: "Wanganui",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6567",
        Branch_Information: "Palmerston North",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6571",
        Branch_Information: "Lower Hutt",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6573",
        Branch_Information: "Parkroyal",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6575",
        Branch_Information: "Willis Street",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6581",
        Branch_Information: "Nelson",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6583",
        Branch_Information: "Blenheim",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6585",
        Branch_Information: "Christchurch",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6587",
        Branch_Information: "Papanui",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6589",
        Branch_Information: "Riccarton",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6593",
        Branch_Information: "Timaru",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6597",
        Branch_Information: "Dunedin",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Bank of New Zealand",
        Branch_Number: "6599",
        Branch_Information: "Invercargill",
        Bank_Number: "08",
    },
    {
        Bank_Name: "Industrial and Commercial Bank of China (New Zealand) Ltd",
        Branch_Number: "5165",
        Branch_Information: "ICBC NZ Ltd",
        Bank_Number: "10",
    },
    {
        Bank_Name: "Industrial and Commercial Bank of China (New Zealand) Ltd",
        Branch_Number: "5166",
        Branch_Information: "ICBC NZ Ltd",
        Bank_Number: "10",
    },
    {
        Bank_Name: "Industrial and Commercial Bank of China (New Zealand) Ltd",
        Branch_Number: "5167",
        Branch_Information: "ICBC NZ Ltd",
        Bank_Number: "10",
    },
    {
        Bank_Name: "Industrial and Commercial Bank of China (New Zealand) Ltd",
        Branch_Number: "5168",
        Branch_Information: "ICBC NZ Ltd",
        Bank_Number: "10",
    },
    {
        Bank_Name: "Industrial and Commercial Bank of China (New Zealand) Ltd",
        Branch_Number: "5169",
        Branch_Information: "ICBC NZ Ltd",
        Bank_Number: "10",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5000",
        Branch_Information: "Whangarei",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5017",
        Branch_Information: "Dargaville",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5026",
        Branch_Information: "Kerikeri",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5027",
        Branch_Information: "Kaitaia",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5029",
        Branch_Information: "Kensington 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5031",
        Branch_Information: "Paihia 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5032",
        Branch_Information: "Kensington",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5033",
        Branch_Information: "Paihia",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5146",
        Branch_Information: "Whangarei 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5147",
        Branch_Information: "Whangarei 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5200",
        Branch_Information: "Auckland, 126 Queen Street",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5201",
        Branch_Information: "Auckland Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5202",
        Branch_Information: "Auckland Opn Support",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5203",
        Branch_Information: "Auckland Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5211",
        Branch_Information: "Albany",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5216",
        Branch_Information: "126 Queen Street 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5220",
        Branch_Information: "New Lynn 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5228",
        Branch_Information: "Howick",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5230",
        Branch_Information: "Birkenhead",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5234",
        Branch_Information: "Browns Bay",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5242",
        Branch_Information: "Manurewa 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5249",
        Branch_Information: "Devonport",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5250",
        Branch_Information: "St Lukes 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5253",
        Branch_Information: "Panmure 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5254",
        Branch_Information: "St Lukes 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5264",
        Branch_Information: "New Lynn 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5265",
        Branch_Information: "Glenfield",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5267",
        Branch_Information: "Panmure 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5274",
        Branch_Information: "Ponsonby 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5275",
        Branch_Information: "Huapai/Kumeu",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5276",
        Branch_Information: "Henderson 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5277",
        Branch_Information: "Henderson",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5284",
        Branch_Information: "Howick",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5299",
        Branch_Information: "St Lukes 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5301",
        Branch_Information: "Huapai/Kumeu",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5313",
        Branch_Information: "Papatoetoe 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5314",
        Branch_Information: "Hunters Corner",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5316",
        Branch_Information: "Papatoetoe 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5318",
        Branch_Information: "Manurewa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5332",
        Branch_Information: "Milford",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5337",
        Branch_Information: "St Lukes",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5338",
        Branch_Information: "St Lukes 7",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5339",
        Branch_Information: "Mt Roskill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5344",
        Branch_Information: "New Lynn",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5345",
        Branch_Information: "Newmarket",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5346",
        Branch_Information: "Newton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5350",
        Branch_Information: "Onehunga",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5351",
        Branch_Information: "Waiheke Island",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5358",
        Branch_Information: "Orewa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5360",
        Branch_Information: "Otahuhu",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5362",
        Branch_Information: "Hunters Corner 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5369",
        Branch_Information: "Pakuranga",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5372",
        Branch_Information: "Panmure",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5373",
        Branch_Information: "Papakura",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5377",
        Branch_Information: "Papatoetoe",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5389",
        Branch_Information: "St Lukes 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5392",
        Branch_Information: "Ponsonby",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5397",
        Branch_Information: "Henderson 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5400",
        Branch_Information: "Pukekohe",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5401",
        Branch_Information: "Auckland (Queen and Victoria Streets)",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5402",
        Branch_Information: "Landmark House",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5407",
        Branch_Information: "Lincoln North",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5409",
        Branch_Information: "Remuera",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5420",
        Branch_Information: "St Heliers",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5422",
        Branch_Information: "St Lukes 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5432",
        Branch_Information: "Newton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5434",
        Branch_Information: "Takapuna",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5438",
        Branch_Information: "Takapuna 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5441",
        Branch_Information: "Newmarket",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5443",
        Branch_Information: "Mt Roskill 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5448",
        Branch_Information: "Pukekohe 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5458",
        Branch_Information: "Pukekohe 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5460",
        Branch_Information: "Warkworth",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5462",
        Branch_Information: "Auckland (Queen and Victoria Streets)",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5463",
        Branch_Information: "Warkworth 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5468",
        Branch_Information: "Orewa 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5495",
        Branch_Information: "Manukau",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5508",
        Branch_Information: "126 Queen St 9",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5509",
        Branch_Information: "Transaction Services",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5510",
        Branch_Information: "126 Queen Street 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5511",
        Branch_Information: "126 Queen Street 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5512",
        Branch_Information: "126 Queen Street 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5513",
        Branch_Information: "126 Queen Street 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5514",
        Branch_Information: "126 Queen Street 7",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5515",
        Branch_Information: "126 Queen Street 8",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5527",
        Branch_Information: "Pobl Credit Card Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5700",
        Branch_Information: "Ward Street",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5704",
        Branch_Information: "Osc Hamilton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5712",
        Branch_Information: "Ward Street 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5715",
        Branch_Information: "Cambridge",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5719",
        Branch_Information: "Frankton 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5720",
        Branch_Information: "Chartwell 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5722",
        Branch_Information: "Frankton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5724",
        Branch_Information: "Ward Street 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5725",
        Branch_Information: "Ward Street 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5727",
        Branch_Information: "Hamilton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5731",
        Branch_Information: "Hamilton 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5736",
        Branch_Information: "Huntly",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5751",
        Branch_Information: "Tokoroa 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5755",
        Branch_Information: "Matamata",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5760",
        Branch_Information: "Morrinsville",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5762",
        Branch_Information: "Frankton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5765",
        Branch_Information: "Te Rapa 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5773",
        Branch_Information: "Otorohanga",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5785",
        Branch_Information: "Te Rapa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5789",
        Branch_Information: "Tokoroa 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5804",
        Branch_Information: "Taumarunui",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5809",
        Branch_Information: "Te Awamutu",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5814",
        Branch_Information: "Te Kuiti",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5827",
        Branch_Information: "Tokoroa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5832",
        Branch_Information: "Ward St",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5849",
        Branch_Information: "Chartwell",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5852",
        Branch_Information: "Ward Street 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5853",
        Branch_Information: "Ward Street 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5859",
        Branch_Information: "Ward Street 7",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5900",
        Branch_Information: "Thames",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5925",
        Branch_Information: "Paeroa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5931",
        Branch_Information: "Morrinsville 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5934",
        Branch_Information: "Katikati 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5941",
        Branch_Information: "Thames 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "5943",
        Branch_Information: "Whitianga",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6000",
        Branch_Information: "Tauranga",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6001",
        Branch_Information: "Tauranga 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6010",
        Branch_Information: "Bayfair",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6013",
        Branch_Information: "Cherrywood",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6015",
        Branch_Information: "Greerton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6017",
        Branch_Information: "Katikati",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6022",
        Branch_Information: "Mt Maunganui",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6030",
        Branch_Information: "Tauranga 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6031",
        Branch_Information: "Te Puke",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6100",
        Branch_Information: "Rotorua",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6102",
        Branch_Information: "Rotorua 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6115",
        Branch_Information: "Whakatane 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6123",
        Branch_Information: "Kawerau",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6147",
        Branch_Information: "Opotiki",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6163",
        Branch_Information: "Rotorua 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6168",
        Branch_Information: "Taupo",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6183",
        Branch_Information: "Taupo 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6187",
        Branch_Information: "Rotorua 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6189",
        Branch_Information: "Whakatane",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6300",
        Branch_Information: "Gisborne 1",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6310",
        Branch_Information: "Gisborne 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6347",
        Branch_Information: "Gisborne 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6400",
        Branch_Information: "Napier",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6401",
        Branch_Information: "Napier 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6421",
        Branch_Information: "Hastings",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6422",
        Branch_Information: "Hastings 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6424",
        Branch_Information: "Havelock North",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6432",
        Branch_Information: "Marewa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6439",
        Branch_Information: "Hastings 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6459",
        Branch_Information: "Stortford Lodge",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6460",
        Branch_Information: "Havelock North 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6462",
        Branch_Information: "Taradale",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6477",
        Branch_Information: "Waipukurau 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6478",
        Branch_Information: "Waipukurau",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6479",
        Branch_Information: "Wairoa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6600",
        Branch_Information: "New Plymouth",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6601",
        Branch_Information: "New Plymouth 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6620",
        Branch_Information: "Stratford 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6621",
        Branch_Information: "New Plymouth 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6623",
        Branch_Information: "Hawera",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6627",
        Branch_Information: "Stratford 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6629",
        Branch_Information: "Stratford 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6634",
        Branch_Information: "Hawera 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6654",
        Branch_Information: "Hawera 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6660",
        Branch_Information: "Stratford",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6676",
        Branch_Information: "New Plymouth 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6800",
        Branch_Information: "Wanganui",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6820",
        Branch_Information: "Wanganui 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6833",
        Branch_Information: "Marton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6836",
        Branch_Information: "Wanganui 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6840",
        Branch_Information: "Wanganui 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6841",
        Branch_Information: "Taihape 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6847",
        Branch_Information: "Hawera 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6849",
        Branch_Information: "Wanganui 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6855",
        Branch_Information: "Taihape",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6862",
        Branch_Information: "Wanganui 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6900",
        Branch_Information: "Palmerston North",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6901",
        Branch_Information: "Palmerston North",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6902",
        Branch_Information: "Palmerston North 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6903",
        Branch_Information: "Palmerston North 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6912",
        Branch_Information: "Palmerston North",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6916",
        Branch_Information: "Dannevirke",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6917",
        Branch_Information: "Feilding",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6919",
        Branch_Information: "Levin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6932",
        Branch_Information: "Levin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6952",
        Branch_Information: "Pahiatua",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6962",
        Branch_Information: "Levin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6965",
        Branch_Information: "Terrace End 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6972",
        Branch_Information: "Pahiatua 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "6974",
        Branch_Information: "Palmerston North 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7000",
        Branch_Information: "Terrace End 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7001",
        Branch_Information: "Palmerston North 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7026",
        Branch_Information: "Postal Agency Office",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7100",
        Branch_Information: "Masterton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7114",
        Branch_Information: "Masterton 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7116",
        Branch_Information: "Masterton 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7117",
        Branch_Information: "Martinborough 1",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7119",
        Branch_Information: "Masterton 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7123",
        Branch_Information: "Masterton 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7200",
        Branch_Information: "Victoria Street Central 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7202",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7203",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7216",
        Branch_Information: "North End 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7219",
        Branch_Information: "Porirua 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7220",
        Branch_Information: "Victoria St Central",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7221",
        Branch_Information: "Petone 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7231",
        Branch_Information: "Johnsonville",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7234",
        Branch_Information: "Johnsonville 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7239",
        Branch_Information: "Kilbirnie",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7244",
        Branch_Information: "Lower Hutt 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7247",
        Branch_Information: "Victoria Street Central",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7249",
        Branch_Information: "Kilbirnie 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7250",
        Branch_Information: "Lower Hutt",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7251",
        Branch_Information: "Lower Hutt 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7255",
        Branch_Information: "Otaki",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7259",
        Branch_Information: "Paraparaumu",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7260",
        Branch_Information: "Paraparaumu Beach",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7265",
        Branch_Information: "Petone",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7267",
        Branch_Information: "Porirua",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7278",
        Branch_Information: "Tawa",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7281",
        Branch_Information: "Lower Hutt 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7282",
        Branch_Information: "North End 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7283",
        Branch_Information: "Porirua",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7284",
        Branch_Information: "Upper Hutt 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7286",
        Branch_Information: "Upper Hutt",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7287",
        Branch_Information: "Upper Hutt 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7290",
        Branch_Information: "Waikanae",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7292",
        Branch_Information: "Lower Hutt 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7300",
        Branch_Information: "Porirua 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7302",
        Branch_Information: "Victoria St Central",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7309",
        Branch_Information: "Victoria Street Central 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7311",
        Branch_Information: "Lower Hutt 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7313",
        Branch_Information: "Victoria Street Central 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7314",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7318",
        Branch_Information: "North End 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7319",
        Branch_Information: "Postbank North End",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7320",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7328",
        Branch_Information: "North End 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7329",
        Branch_Information: "Victoria Street Central 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7340",
        Branch_Information: "Postbank Eftpos Reconcilation",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7341",
        Branch_Information: "Special Housing",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7342",
        Branch_Information: "Special Housing",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7343",
        Branch_Information: "Mortgages 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7344",
        Branch_Information: "Mortgages 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7345",
        Branch_Information: "Mortgages 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7346",
        Branch_Information: "Mortgages 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7347",
        Branch_Information: "Mortgages 7",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7348",
        Branch_Information: "Mortgages 8",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7349",
        Branch_Information: "Special Housing",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7400",
        Branch_Information: "Nelson",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7402",
        Branch_Information: "Nelson 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7426",
        Branch_Information: "Motueka",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7428",
        Branch_Information: "Nelson 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7436",
        Branch_Information: "Eds Test Branch 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7438",
        Branch_Information: "Richmond",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7443",
        Branch_Information: "Richmond 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7446",
        Branch_Information: "Motueka 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7500",
        Branch_Information: "Blenheim 1",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7517",
        Branch_Information: "Blenheim 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7528",
        Branch_Information: "Blenheim 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7532",
        Branch_Information: "Blenheim 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7600",
        Branch_Information: "Greymouth",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7626",
        Branch_Information: "Hokitika",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7653",
        Branch_Information: "Greymouth 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7700",
        Branch_Information: "Westport",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7800",
        Branch_Information: "Christchurch",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7802",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7803",
        Branch_Information: "Wellington Support Centre",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7810",
        Branch_Information: "Addington 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7811",
        Branch_Information: "Hornby 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7814",
        Branch_Information: "Christchurch 10",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7816",
        Branch_Information: "Ashburton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7820",
        Branch_Information: "Addington",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7824",
        Branch_Information: "Papanui 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7848",
        Branch_Information: "Merivale 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7850",
        Branch_Information: "Merivale",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7854",
        Branch_Information: "New Brighton 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7856",
        Branch_Information: "Halswell",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7860",
        Branch_Information: "Christchurch 9",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7865",
        Branch_Information: "Hornby",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7870",
        Branch_Information: "Kaiapoi",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7876",
        Branch_Information: "Hornby 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7881",
        Branch_Information: "Woolston 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7885",
        Branch_Information: "Woolston 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7887",
        Branch_Information: "Merivale",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7888",
        Branch_Information: "Avonhead",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7889",
        Branch_Information: "Ashburton 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7892",
        Branch_Information: "New Brighton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7893",
        Branch_Information: "Shirley 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7901",
        Branch_Information: "Papanui",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7909",
        Branch_Information: "Rangiora",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7911",
        Branch_Information: "Riccarton",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7916",
        Branch_Information: "Papanui 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7920",
        Branch_Information: "Shirley 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7931",
        Branch_Information: "Woolston 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7932",
        Branch_Information: "Sydenham",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7947",
        Branch_Information: "Christchurch 11",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "7956",
        Branch_Information: "Woolston",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8000",
        Branch_Information: "Christchurch 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8001",
        Branch_Information: "Christchurch 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8003",
        Branch_Information: "Christchurch 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8004",
        Branch_Information: "Christchurch 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8005",
        Branch_Information: "Cap Processing",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8007",
        Branch_Information: "Christchurch 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8008",
        Branch_Information: "Christchurch 7",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8013",
        Branch_Information: "Christchurch 8",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8100",
        Branch_Information: "Timaru",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8102",
        Branch_Information: "Timaru",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8105",
        Branch_Information: "Timaru 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8116",
        Branch_Information: "Geraldine",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8117",
        Branch_Information: "Geraldine 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8120",
        Branch_Information: "Timaru",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8138",
        Branch_Information: "Temuka",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8144",
        Branch_Information: "Timaru 6",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8145",
        Branch_Information: "Waimate",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8147",
        Branch_Information: "Timaru",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8200",
        Branch_Information: "Oamaru",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8300",
        Branch_Information: "Dunedin 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8310",
        Branch_Information: "Alexandra",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8313",
        Branch_Information: "George Street",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8314",
        Branch_Information: "Balclutha",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8322",
        Branch_Information: "South Dunedin 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8327",
        Branch_Information: "Alexandra 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8330",
        Branch_Information: "George Street 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8337",
        Branch_Information: "George Street 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8338",
        Branch_Information: "Mosgiel 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8339",
        Branch_Information: "Dunedin 8",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8365",
        Branch_Information: "Dunedin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8367",
        Branch_Information: "Mosgiel",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8378",
        Branch_Information: "George St 5",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8381",
        Branch_Information: "George Street 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8386",
        Branch_Information: "Dunedin 9",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8387",
        Branch_Information: "Alexandra 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8390",
        Branch_Information: "South Dunedin 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8393",
        Branch_Information: "South Dunedin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8406",
        Branch_Information: "Alexandra 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8422",
        Branch_Information: "Balclutha 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8425",
        Branch_Information: "Dunedin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8426",
        Branch_Information: "Dunedin 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8427",
        Branch_Information: "Dunedin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8428",
        Branch_Information: "Dunedin",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8429",
        Branch_Information: "Dunedin 7",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8431",
        Branch_Information: "Bonus Bonds",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8500",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8503",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8505",
        Branch_Information: "St Kilda Processing",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8515",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8530",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8532",
        Branch_Information: "Gore",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8537",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8552",
        Branch_Information: "Gore 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8556",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8564",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8570",
        Branch_Information: "Queenstown",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8572",
        Branch_Information: "Invercargill 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8576",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8593",
        Branch_Information: "Invercargill",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8750",
        Branch_Information: "Postbank Test Branch 1",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8760",
        Branch_Information: "Postbank Test Branch 2",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8770",
        Branch_Information: "Postbank Test Branch 3",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8780",
        Branch_Information: "Postbank Test Branch 4",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8991",
        Branch_Information: "Postbank Loans Test Branch",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8994",
        Branch_Information: "New Zealand Headquarters",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8995",
        Branch_Information: "New Zealand Headquarters",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8996",
        Branch_Information: "National Accounting",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8997",
        Branch_Information: "Head Office Test",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "8999",
        Branch_Information: "Postbank Registered Payees",
        Bank_Number: "11",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3001",
        Branch_Information: "ASB Testing Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3002",
        Branch_Information: "Processing Training Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3003",
        Branch_Information: "ASB Testing Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3006",
        Branch_Information: "Lending Department",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3007",
        Branch_Information: "National Operations",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3008",
        Branch_Information: "Cheques Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3009",
        Branch_Information: "ASB Bank Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3010",
        Branch_Information: "CC Dom Rd Centralised  Costs",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3011",
        Branch_Information: "Auckland",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3012",
        Branch_Information: "Newton",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3013",
        Branch_Information: "Newmarket",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3014",
        Branch_Information: "Onehunga",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3015",
        Branch_Information: "Devonport",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3016",
        Branch_Information: "Dominion Road",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3017",
        Branch_Information: "Symonds Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3018",
        Branch_Information: "Otahuhu",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3019",
        Branch_Information: "Ponsonby",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3020",
        Branch_Information: "Pt Chevalier",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3021",
        Branch_Information: "Greenlane",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3022",
        Branch_Information: "Grey Lynn",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3023",
        Branch_Information: "Pukekohe",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3024",
        Branch_Information: "Karangahape Road",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3025",
        Branch_Information: "Greenwoods Corner",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3026",
        Branch_Information: "Takapuna",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3027",
        Branch_Information: "St Heliers",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3028",
        Branch_Information: "Papatoetoe",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3029",
        Branch_Information: "Mt Albert",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3030",
        Branch_Information: "Remuera",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3031",
        Branch_Information: "Papakura",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3032",
        Branch_Information: "Manurewa",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3033",
        Branch_Information: "Roskill",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3034",
        Branch_Information: "Lynnmall Branch - New Lynn",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3035",
        Branch_Information: "Birkenhead",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3036",
        Branch_Information: "Panmure",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3037",
        Branch_Information: "Customs Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3038",
        Branch_Information: "Te Atatu Peninsula",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3039",
        Branch_Information: "Henderson",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3040",
        Branch_Information: "Howick",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3041",
        Branch_Information: "Glen Innes",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3042",
        Branch_Information: "Mairangi",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3043",
        Branch_Information: "Mt Roskill South",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3044",
        Branch_Information: "Hunters Plaza",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3045",
        Branch_Information: "Avondale",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3046",
        Branch_Information: "Orewa",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3047",
        Branch_Information: "Orakei",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3048",
        Branch_Information: "Mt Eden",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3049",
        Branch_Information: "Blockhouse Bay",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3050",
        Branch_Information: "Milford Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3051",
        Branch_Information: "Glen Eden",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3052",
        Branch_Information: "Waiuku",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3053",
        Branch_Information: "Northcote",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3054",
        Branch_Information: "Mangere Bridge",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3055",
        Branch_Information: "Penrose",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3056",
        Branch_Information: "Pakuranga",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3057",
        Branch_Information: "Wyndham",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3058",
        Branch_Information: "Sylvia Park",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3059",
        Branch_Information: "Browns Bay",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3060",
        Branch_Information: "Ellerslie",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3061",
        Branch_Information: "Royal Oak",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3062",
        Branch_Information: "Otara",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3063",
        Branch_Information: "Sandringham",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3064",
        Branch_Information: "Hobson Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3065",
        Branch_Information: "Belmont",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3066",
        Branch_Information: "University",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3067",
        Branch_Information: "Balmoral",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3068",
        Branch_Information: "Owairaka",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3069",
        Branch_Information: "Three Kings",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3070",
        Branch_Information: "Green Bay",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3071",
        Branch_Information: "South Te Atatu",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3072",
        Branch_Information: "Glenfield",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3073",
        Branch_Information: "Eastridge",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3074",
        Branch_Information: "Kelston",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3075",
        Branch_Information: "Parnell",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3076",
        Branch_Information: "Bader Drive",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3077",
        Branch_Information: "St Lukes",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3078",
        Branch_Information: "Tuakau",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3079",
        Branch_Information: "Mangere East",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3080",
        Branch_Information: "Torbay",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3081",
        Branch_Information: "Downtown",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3082",
        Branch_Information: "Lynfield",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3083",
        Branch_Information: "Manukau City",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3084",
        Branch_Information: "Whangaparaoa",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3085",
        Branch_Information: "Westgate",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3086",
        Branch_Information: "Meadowbank",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3087",
        Branch_Information: "Sunnynook",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3088",
        Branch_Information: "Princess Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3089",
        Branch_Information: "Highland Park",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3090",
        Branch_Information: "Kawakawa",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3091",
        Branch_Information: "Kerikeri",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3092",
        Branch_Information: "Onerahi",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3093",
        Branch_Information: "Kamo",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3094",
        Branch_Information: "Wellsford",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3095",
        Branch_Information: "Warkworth",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3096",
        Branch_Information: "Kaitaia",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3097",
        Branch_Information: "Kaikohe",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3098",
        Branch_Information: "Dargaville",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3099",
        Branch_Information: "Whangarei",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3100",
        Branch_Information: "Titirangi",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3101",
        Branch_Information: "Kensington",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3102",
        Branch_Information: "Paihia",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3103",
        Branch_Information: "Henderson West",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3104",
        Branch_Information: "Clendon",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3105",
        Branch_Information: "Snells Beach",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3106",
        Branch_Information: "Northland Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3107",
        Branch_Information: "North Harbour Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3108",
        Branch_Information: "West Akld Regional Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3109",
        Branch_Information: "Central Auck Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3110",
        Branch_Information: "East Auck Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3111",
        Branch_Information: "Counties Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3112",
        Branch_Information: "ASB Institutional Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3113",
        Branch_Information: "Institutional Banking Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3114",
        Branch_Information: "Waiheke",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3115",
        Branch_Information: "Walton Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3116",
        Branch_Information: "Consumer Finance",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3117",
        Branch_Information: "Cavendish Drive",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3118",
        Branch_Information: "Central Park Service Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3119",
        Branch_Information: "Wairau Park",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3120",
        Branch_Information: "Managed Funds",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3121",
        Branch_Information: "ASB Bank International",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3122",
        Branch_Information: "Hamilton",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3123",
        Branch_Information: "Waikato Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3124",
        Branch_Information: "Private Banking Christchurch 4",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3125",
        Branch_Information: "Digital 4",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3126",
        Branch_Information: "Digital 5",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3127",
        Branch_Information: "Subsidiaries Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3128",
        Branch_Information: "Nelson Commercial Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3129",
        Branch_Information: "International Trade Services",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3130",
        Branch_Information: "Business Serv & Sup Desk",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3131",
        Branch_Information: "Collections & Credit Solutions",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3132",
        Branch_Information: "Meadowlands",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3134",
        Branch_Information: "Te Awamutu",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3135",
        Branch_Information: "New Plymouth",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3136",
        Branch_Information: "Albany",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3137",
        Branch_Information: "Two Double Seven Newmarket Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3138",
        Branch_Information: "Lynmall Shopping Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3139",
        Branch_Information: "Helensville",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3140",
        Branch_Information: "Lambton Quay",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3141",
        Branch_Information: "Willis Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3142",
        Branch_Information: "Queensgate",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3143",
        Branch_Information: "Palmerston North",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3144",
        Branch_Information: "Napier",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3145",
        Branch_Information: "Hastings",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3146",
        Branch_Information: "Tauranga Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3147",
        Branch_Information: "Riccarton",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3148",
        Branch_Information: "Cashel Mall",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3149",
        Branch_Information: "Northlands Branch - Papanui",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3150",
        Branch_Information: "Dunedin",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3151",
        Branch_Information: "Armagh Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3152",
        Branch_Information: "Anglesea Clinic",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3153",
        Branch_Information: "Lincoln (Christchurch)",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3154",
        Branch_Information: "Invercargill",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3155",
        Branch_Information: "Rotorua",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3157",
        Branch_Information: "Paraparaumu",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3158",
        Branch_Information: "Richmond",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3159",
        Branch_Information: "Timaru",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3160",
        Branch_Information: "Private Banking West Auckland 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3161",
        Branch_Information: "Reefton",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3162",
        Branch_Information: "Taupo",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3163",
        Branch_Information: "Wanganui",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3164",
        Branch_Information: "Murchison",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3165",
        Branch_Information: "Nelson Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3166",
        Branch_Information: "Hokitika",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3167",
        Branch_Information: "Blenheim",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3168",
        Branch_Information: "Greymouth",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3169",
        Branch_Information: "High Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3170",
        Branch_Information: "Gisborne",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3171",
        Branch_Information: "Chartwell",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3172",
        Branch_Information: "Stoke Personal Banking Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3173",
        Branch_Information: "Caravan Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3174",
        Branch_Information: "Wellington",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3175",
        Branch_Information: "Northland Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3176",
        Branch_Information: "North Waikato Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3177",
        Branch_Information: "Training Centre Greenlane",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3178",
        Branch_Information: "Westport",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3179",
        Branch_Information: "Bay Of Plenty Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3180",
        Branch_Information: "Rotorua Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3181",
        Branch_Information: "Canterbury Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3182",
        Branch_Information: "Nelson Westland Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3183",
        Branch_Information: "Hawkes Bay & Wairarapa Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3184",
        Branch_Information: "Southland Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3185",
        Branch_Information: "Otago Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3186",
        Branch_Information: "Manawatu & Wanganui Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3187",
        Branch_Information: "Taranaki Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3188",
        Branch_Information: "International Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3189",
        Branch_Information: "Te Awamutu Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3190",
        Branch_Information: "Matamata Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3191",
        Branch_Information: "Canterbury Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3192",
        Branch_Information: "Wellington Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3193",
        Branch_Information: "Nelson Regional Office",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3194",
        Branch_Information: "Commercial Banking Tauranga",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3195",
        Branch_Information: "Southland Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3196",
        Branch_Information: "Dunedin Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3197",
        Branch_Information: "Napier Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3198",
        Branch_Information: "Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3199",
        Branch_Information: "Home Loan Line",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3200",
        Branch_Information: "Self Service Terminals",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3201",
        Branch_Information: "Mobile Banking Administration",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3205",
        Branch_Information: "Federal Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3207",
        Branch_Information: "Personal Credit Unit",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3208",
        Branch_Information: "H/O Personal Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3209",
        Branch_Information: "Business Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3210",
        Branch_Information: "Electronic Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3211",
        Branch_Information: "Palmerston North Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3212",
        Branch_Information: "Taranaki Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3213",
        Branch_Information: "Mid Canterbury Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3214",
        Branch_Information: "South Taranaki Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3215",
        Branch_Information: "Payments Operations",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3216",
        Branch_Information: "125 Queen Street",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3217",
        Branch_Information: "Bayfair",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3218",
        Branch_Information: "Aafs Alliance",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3219",
        Branch_Information: "ASB Tertiary Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3220",
        Branch_Information: "Bankdirect",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3221",
        Branch_Information: "Property Finance",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3222",
        Branch_Information: "Professional Trust Funds",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3223",
        Branch_Information: "Johnsonville",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3224",
        Branch_Information: "Whangarei South Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3225",
        Branch_Information: "Morrinsville Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3226",
        Branch_Information: "Farmline Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3227",
        Branch_Information: "King Country Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3228",
        Branch_Information: "South Canterbury Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3229",
        Branch_Information: "West Otago & Southland Rural",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3230",
        Branch_Information: "Korean Banking Unit",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3231",
        Branch_Information: "ASB Securities",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3232",
        Branch_Information: "Westcity",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3233",
        Branch_Information: "Botany Downs",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3234",
        Branch_Information: "Queenstown",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3235",
        Branch_Information: "CMU",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3236",
        Branch_Information: "Sovereign Home Loans Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3237",
        Branch_Information: "East Tamaki Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3238",
        Branch_Information: "Whangarei Hvc Unit",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3239",
        Branch_Information: "Contact Centre C:Drive",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3240",
        Branch_Information: "The Palms Branch - Shirley",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3241",
        Branch_Information: "Kiwi Bank Bureau Services",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3242",
        Branch_Information: "Client Services",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3243",
        Branch_Information: "Citrix Metaframe",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3244",
        Branch_Information: "Corporate Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3245",
        Branch_Information: "Wellington Corporate Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3246",
        Branch_Information: "Christchurch Corporate Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3247",
        Branch_Information: "Hawera",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3248",
        Branch_Information: "North Harbour Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3249",
        Branch_Information: "Waikato University Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3250",
        Branch_Information: "Self Service Terminals 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3251",
        Branch_Information: "EDS Bulk Cheque Deposits",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3252",
        Branch_Information: "West Auckland Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3253",
        Branch_Information: "Whakatane",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3254",
        Branch_Information: "Porirua",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3255",
        Branch_Information: "Wanganui Rural Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3256",
        Branch_Information: "Masterton Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3257",
        Branch_Information: "Whakatane Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3258",
        Branch_Information: "Western/Northern Southland Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3259",
        Branch_Information: "Eastern Southland Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3260",
        Branch_Information: "Premier Banking Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3261",
        Branch_Information: "Central Auckland Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3262",
        Branch_Information: "Support Centre Two",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3263",
        Branch_Information: "Rotorua Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3264",
        Branch_Information: "Contact  Centre Team PukaPuka",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3265",
        Branch_Information: "Outbound Specialists Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3266",
        Branch_Information: "Contact Centre Team Hibiscus",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3267",
        Branch_Information: "ASB Cards Private Label",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3268",
        Branch_Information: "Cambridge Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3269",
        Branch_Information: "North Canterbury Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3270",
        Branch_Information: "Self Service Terminals 3",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3271",
        Branch_Information: "Ashburton Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3272",
        Branch_Information: "Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3273",
        Branch_Information: "Contact Centre Staff First",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3274",
        Branch_Information: "West Auckland Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3275",
        Branch_Information: "Christchurch Region Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3276",
        Branch_Information: "Counties Region Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3277",
        Branch_Information: "East Auckland Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3278",
        Branch_Information: "Hamilton Region Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3279",
        Branch_Information: "Tauranga Region Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3280",
        Branch_Information: "Wellington Region Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3281",
        Branch_Information: "Northland Region Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3282",
        Branch_Information: "Private Banking East Auckland 4",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3284",
        Branch_Information: "Wellington Processing Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3285",
        Branch_Information: "Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3286",
        Branch_Information: "National Operations AKLD",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3287",
        Branch_Information: "Sylvia Park Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3288",
        Branch_Information: "Business Banking Development",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3289",
        Branch_Information: "Business Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3290",
        Branch_Information: "Masterton Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3292",
        Branch_Information: "Hamilton Airport",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3295",
        Branch_Information: "Debt Assessment And Recoveries",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3296",
        Branch_Information: "Ti Test Lab A & B",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3400",
        Branch_Information: "Hutt Valley Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3401",
        Branch_Information: "Asian Banking Centre - Christchurch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3402",
        Branch_Information: "Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3403",
        Branch_Information: "Major Commercial Group",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3404",
        Branch_Information: "MLM Service Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3405",
        Branch_Information: "Remarkables Park Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3406",
        Branch_Information: "Consumer FX",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3407",
        Branch_Information: "Papamoa Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3408",
        Branch_Information: "Contact  Centre Team Nikau",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3409",
        Branch_Information: "Contact  Centre Team Rimu",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3410",
        Branch_Information: "Contact  Centre Team Puriri",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3411",
        Branch_Information: "Contact  Centre Team Kanuka",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3412",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3413",
        Branch_Information: "Contact  Centre Team Miro",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3414",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3415",
        Branch_Information: "Contact  Centre Team Kowhai",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3416",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3417",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3418",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3419",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3420",
        Branch_Information: "Contact  Centre Team Kohekohe",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3421",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3422",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3423",
        Branch_Information: "Contact  Centre Team Koromiko",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3424",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3425",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3426",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3427",
        Branch_Information: "Broadway Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3428",
        Branch_Information: "Auckland University",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3429",
        Branch_Information: "Pago Business",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3430",
        Branch_Information: "Victoria Street West Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3431",
        Branch_Information: "Thames Valley Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3432",
        Branch_Information: "Christchurch Processing Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3433",
        Branch_Information: "ASB - True Rewards",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3434",
        Branch_Information: "Gore Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3435",
        Branch_Information: "ASB Smales Farm",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3436",
        Branch_Information: "Botany Junction",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3437",
        Branch_Information: "Morrinsville",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3438",
        Branch_Information: "Matamata",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3439",
        Branch_Information: "Havelock North",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3440",
        Branch_Information: "Greerton",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3441",
        Branch_Information: "Hornby",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3442",
        Branch_Information: "Terrace End",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3443",
        Branch_Information: "ASB Rural Corporate Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3445",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3446",
        Branch_Information: "Contact Centre Team Toatoa",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3447",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3448",
        Branch_Information: "Direct Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3449",
        Branch_Information: "Contact Centre Team Mahoe",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3450",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3451",
        Branch_Information: "Contact Centre Team Kamahi",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3452",
        Branch_Information: "Contact  Centre Team Koru",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3453",
        Branch_Information: "Ronwood Avenue",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3454",
        Branch_Information: "Te Rapa",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3455",
        Branch_Information: "Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3456",
        Branch_Information: "Pago Personal",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3457",
        Branch_Information: "Auckland Broker Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3458",
        Branch_Information: "ASB Contact Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3459",
        Branch_Information: "Rural Banking Solutions",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3460",
        Branch_Information: "Commercial Banking Solutions",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3461",
        Branch_Information: "Corporate Banking Solutions",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3462",
        Branch_Information: "Home Plus",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3474",
        Branch_Information: "Card Operations",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3475",
        Branch_Information: "Timaru Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3476",
        Branch_Information: "Hamilton Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3477",
        Branch_Information: "Palmerston North Plaza",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3478",
        Branch_Information: "Upper Hutt",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3479",
        Branch_Information: "Taradale",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3480",
        Branch_Information: "CAP - Price Negotiations",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3481",
        Branch_Information: "Kilbirnie",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3482",
        Branch_Information: "Barrington Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3483",
        Branch_Information: "Ferrymead",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3484",
        Branch_Information: "Constellation Drive",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3485",
        Branch_Information: "Mosgiel Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3486",
        Branch_Information: "Mt Maunganui",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3488",
        Branch_Information: "Auckland Central Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3489",
        Branch_Information: "North Shore Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3490",
        Branch_Information: "Manukau Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3491",
        Branch_Information: "Bays/Lower North Island Premium Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3492",
        Branch_Information: "Central North Island Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3493",
        Branch_Information: "Lower North Island Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3494",
        Branch_Information: "South Island Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3495",
        Branch_Information: "ASB Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3496",
        Branch_Information: "BMs Auckland Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3497",
        Branch_Information: "BMs Out of Auckland Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3498",
        Branch_Information: "Video Team Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3499",
        Branch_Information: "Business Banking Consultants",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3601",
        Branch_Information: "Asian Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3602",
        Branch_Information: "Franchise Business Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3603",
        Branch_Information: "Migrant Broker Unit",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3604",
        Branch_Information: "Blenheim Rural Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3605",
        Branch_Information: "Blenheim Commercial Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3606",
        Branch_Information: "Marshland Road",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3607",
        Branch_Information: "Contact Centre Team Taraire",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3610",
        Branch_Information: "North Harbour Premier Centre 1",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3611",
        Branch_Information: "ASB Private Banking - Constellation Drive",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3612",
        Branch_Information: "Specialist Lending 1",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3613",
        Branch_Information: "Fastnet Business Helpdesk",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3614",
        Branch_Information: "Offsite ATM",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3615",
        Branch_Information: "Ti Rakau Drive",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3616",
        Branch_Information: "Rangiora",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3617",
        Branch_Information: "Auckland Hospital",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3618",
        Branch_Information: "Lunn Ave",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3619",
        Branch_Information: "Asian Business Manager",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3620",
        Branch_Information: "Contact Centre Team Harakeke",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3622",
        Branch_Information: "Lincoln Road Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3623",
        Branch_Information: "East Auckland Premium Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3624",
        Branch_Information: "West Auckland Premium Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3625",
        Branch_Information: "Counties/Waikato Premium Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3626",
        Branch_Information: "Central Auckland Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3627",
        Branch_Information: "East Auckland Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3628",
        Branch_Information: "West Auckland Private Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3629",
        Branch_Information: "Digital Branch",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3630",
        Branch_Information: "Wellington Premium Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3631",
        Branch_Information: "North Wharf",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3632",
        Branch_Information: "Whangarei Premium Banking Centre",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3633",
        Branch_Information: "Cameron Road",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3634",
        Branch_Information: "South Island Premier Banking",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3635",
        Branch_Information: "Private Banking Central North Island",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3637",
        Branch_Information: "ASB Securities No 2 Account",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3638",
        Branch_Information: "ASB Private Banking Nelson",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3639",
        Branch_Information: "ASB Private Banking Dunedin",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3640",
        Branch_Information: "North Harbour Premium Centre 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3641",
        Branch_Information: "Private Banking Wellington 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3642",
        Branch_Information: "Private Banking Christchurch 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3643",
        Branch_Information: "Private Banking North Harbour 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3644",
        Branch_Information: "Private Banking East Auckland 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3645",
        Branch_Information: "Private Banking Central Auckland 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3646",
        Branch_Information: "Private Banking Central Auckland 3",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3647",
        Branch_Information: "Private Banking Central Auckland 4",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3648",
        Branch_Information: "Private Banking Central Auckland 5",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3649",
        Branch_Information: "Private Banking Central Auckland 6",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3650",
        Branch_Information: "Private Banking Central Auckland 7",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3651",
        Branch_Information: "Private Banking Bay of Plenty",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3653",
        Branch_Information: "ASB Takanini",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3654",
        Branch_Information: "ASB Private Banking Central Auckland",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3655",
        Branch_Information: "Private Banking Central Auckland 9",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3656",
        Branch_Information: "Business Banking Negotiations",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3657",
        Branch_Information: "Private Banking LSI 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3658",
        Branch_Information: "Private Banking East Auckland 3",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3659",
        Branch_Information: "NorthWest",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3661",
        Branch_Information: "Silverdale",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3662",
        Branch_Information: "Contact Centre Team Tawhero",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3663",
        Branch_Information: "Contact Centre Team Pokaka",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3664",
        Branch_Information: "Contact Centre Team Taupata",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3665",
        Branch_Information: "Contact Centre Team Karamu",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3666",
        Branch_Information: "Contact Centre Team Pukatea",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3667",
        Branch_Information: "Contact Centre Team Rautini",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3668",
        Branch_Information: "Contact Centre Team Tuakura",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3669",
        Branch_Information: "Contact Centre Team Ramarama",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3670",
        Branch_Information: "Specialist Lending 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3671",
        Branch_Information: "Contact Centre Team Kuripaka",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3672",
        Branch_Information: "Sovereign Home Loans Transactional",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3673",
        Branch_Information: "Stoddard Road",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3674",
        Branch_Information: "Sovereign Home Loans",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3675",
        Branch_Information: "Insurance Servicing Team",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3676",
        Branch_Information: "Private Banking Bay of Plenty 2",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3678",
        Branch_Information: "Asian Banking Commercial",
        Bank_Number: "12",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "3680",
        Branch_Information: "Tauranga Crossing",
        Bank_Number: "12",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4901",
        Branch_Information: "Invercargill City",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4902",
        Branch_Information: "Invercargill City",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4903",
        Branch_Information: "Gore",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4904",
        Branch_Information: "Winton",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4905",
        Branch_Information: "Riverton",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4906",
        Branch_Information: "Otautau",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4907",
        Branch_Information: "Bluff",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4908",
        Branch_Information: "Wyndham",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4909",
        Branch_Information: "Lumsden",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4910",
        Branch_Information: "Mataura",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4911",
        Branch_Information: "Queenstown",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4912",
        Branch_Information: "Tapanui",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4913",
        Branch_Information: "Te Anau",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4914",
        Branch_Information: "Glengarry",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4915",
        Branch_Information: "South City",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4916",
        Branch_Information: "Waikiwi",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4917",
        Branch_Information: "Windsor",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4918",
        Branch_Information: "Esk Street Speedibank",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4919",
        Branch_Information: "Gladstone Speedibank",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4926",
        Branch_Information: "Commercial",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4927",
        Branch_Information: "Money Market",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4928",
        Branch_Information: "Cashflow",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4929",
        Branch_Information: "Training Department",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4930",
        Branch_Information: "Head Office",
        Bank_Number: "13",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4701",
        Branch_Information: "Lending Department",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4702",
        Branch_Information: "Credit Control",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4703",
        Branch_Information: "Staff",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4705",
        Branch_Information: "Clearing Centre",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4707",
        Branch_Information: "Solicitors Trust",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4711",
        Branch_Information: "Dowling Street",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4713",
        Branch_Information: "South Dunedin",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4715",
        Branch_Information: "Gardens",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4717",
        Branch_Information: "Mosgiel",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4719",
        Branch_Information: "George Street",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4723",
        Branch_Information: "Green Island",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4725",
        Branch_Information: "Mornington",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4727",
        Branch_Information: "Moray Place",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4729",
        Branch_Information: "Princes Street Speedibank",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4733",
        Branch_Information: "Roslyn",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4735",
        Branch_Information: "Musselburgh Rise",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4737",
        Branch_Information: "Caversham",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4739",
        Branch_Information: "Port Chalmers",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4741",
        Branch_Information: "St Kilda",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4761",
        Branch_Information: "Oamaru",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4763",
        Branch_Information: "Balclutha",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4765",
        Branch_Information: "Alexandra",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4767",
        Branch_Information: "Roxburgh",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4769",
        Branch_Information: "Ranfurly",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4773",
        Branch_Information: "Milton",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4775",
        Branch_Information: "Cromwell",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4777",
        Branch_Information: "Wanaka",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4779",
        Branch_Information: "Palmerston",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4781",
        Branch_Information: "Waikouaiti",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4783",
        Branch_Information: "North Oamaru Speedibank",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4795",
        Branch_Information: "Money Market",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4796",
        Branch_Information: "Training Department",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4798",
        Branch_Information: "Payline",
        Bank_Number: "14",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4799",
        Branch_Information: "Head Office",
        Bank_Number: "14",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3941",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3942",
        Branch_Information: "TSB Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3943",
        Branch_Information: "TSB Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3944",
        Branch_Information: "Fitzroy",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3945",
        Branch_Information: "Waitara",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3946",
        Branch_Information: "Inglewood",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3947",
        Branch_Information: "Stratford",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3948",
        Branch_Information: "Westown",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3949",
        Branch_Information: "Moturoa",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3950",
        Branch_Information: "Hawera",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3951",
        Branch_Information: "Eltham",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3952",
        Branch_Information: "Opunake",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3953",
        Branch_Information: "TSB Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3954",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3955",
        Branch_Information: "Vogeltown",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3956",
        Branch_Information: "Bell Block",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3957",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3958",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3959",
        Branch_Information: "TSB Customer Engagement Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3960",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3968",
        Branch_Information: "Newmarket",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3969",
        Branch_Information: "Nelson",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3970",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3971",
        Branch_Information: "Wellington",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3972",
        Branch_Information: "Hamilton",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3973",
        Branch_Information: "Tauranga",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3974",
        Branch_Information: "Hastings",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3975",
        Branch_Information: "TSB Service Centre",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3976",
        Branch_Information: "Christchurch",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3977",
        Branch_Information: "Palmerston North",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3978",
        Branch_Information: "Takapuna",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3979",
        Branch_Information: "Napier",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3981",
        Branch_Information: "TSB Support Services",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3987",
        Branch_Information: "Botany",
        Bank_Number: "15",
    },
    {
        Bank_Name: "TSB Bank",
        Branch_Number: "3988",
        Branch_Information: "Northwest",
        Bank_Number: "15",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4402",
        Branch_Information: "Motueka",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4403",
        Branch_Information: "Takaka",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4404",
        Branch_Information: "Richmond",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4405",
        Branch_Information: "Tahunanui",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4406",
        Branch_Information: "Stoke",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4407",
        Branch_Information: "Nelson",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4408",
        Branch_Information: "Nelson",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4409",
        Branch_Information: "Cashflow",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4412",
        Branch_Information: "Picton",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4413",
        Branch_Information: "Redwood Village",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4414",
        Branch_Information: "Kaikoura",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4416",
        Branch_Information: "Blenheim",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4417",
        Branch_Information: "Blenheim",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4423",
        Branch_Information: "Kaiapoi",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4425",
        Branch_Information: "Rangiora",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4428",
        Branch_Information: "Ferry Road",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4430",
        Branch_Information: "Lyttelton",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4431",
        Branch_Information: "Purchasing And Distribution",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4432",
        Branch_Information: "Woolston",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4434",
        Branch_Information: "Sumner",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4435",
        Branch_Information: "Lincoln",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4436",
        Branch_Information: "New Brighton",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4437",
        Branch_Information: "Amberley",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4438",
        Branch_Information: "Aranui",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4439",
        Branch_Information: "Linwood City",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4441",
        Branch_Information: "Linwood",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4443",
        Branch_Information: "Hornby",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4445",
        Branch_Information: "Leeston",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4446",
        Branch_Information: "Upper Riccarton",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4447",
        Branch_Information: "Darfield",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4448",
        Branch_Information: "Riccarton Area Office",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4449",
        Branch_Information: "Riccarton",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4450",
        Branch_Information: "Riccarton Mall",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4451",
        Branch_Information: "University Of Canterbury",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4452",
        Branch_Information: "Ilam",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4453",
        Branch_Information: "Teachers' College",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4454",
        Branch_Information: "Fendalton",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4455",
        Branch_Information: "Fendalton Mall",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4456",
        Branch_Information: "Wairakei Road",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4458",
        Branch_Information: "Bishopdale",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4460",
        Branch_Information: "Papanui",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4461",
        Branch_Information: "Papanui",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4463",
        Branch_Information: "Merivale",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4464",
        Branch_Information: "Parklands",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4465",
        Branch_Information: "Shirley",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4466",
        Branch_Information: "North Avon",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4467",
        Branch_Information: "Shirley Loan Shop",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4468",
        Branch_Information: "Edgeware",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4470",
        Branch_Information: "Sydenham",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4472",
        Branch_Information: "Halswell",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4473",
        Branch_Information: "Addington",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4475",
        Branch_Information: "Spreydon",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4476",
        Branch_Information: "Beckenham",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4477",
        Branch_Information: "St Martins",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4479",
        Branch_Information: "South City",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4480",
        Branch_Information: "Teleservicing Centre",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4481",
        Branch_Information: "Victoria Square",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4482",
        Branch_Information: "Victoria Square",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4483",
        Branch_Information: "141 Hereford Street Christchurch",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4484",
        Branch_Information: "Canterbury Centre",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4485",
        Branch_Information: "Canterbury Centre",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4486",
        Branch_Information: "Christchurch",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4487",
        Branch_Information: "Christchurch",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4488",
        Branch_Information: "Christchurch",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4490",
        Branch_Information: "Support Centre",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4492",
        Branch_Information: "Cashflow",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4493",
        Branch_Information: "Lending Department",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4496",
        Branch_Information: "Clearing Centre",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4497",
        Branch_Information: "Money Market",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4498",
        Branch_Information: "Head Office",
        Bank_Number: "16",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3331",
        Branch_Information: "Foreign Exchange",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3332",
        Branch_Information: "Money Market",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3360",
        Branch_Information: "Te Kauwhata",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3361",
        Branch_Information: "Te Rapa",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3362",
        Branch_Information: "Cambridge",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3363",
        Branch_Information: "Dinsdale",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3364",
        Branch_Information: "Five Cross Roads",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3365",
        Branch_Information: "Frankton",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3366",
        Branch_Information: "Hamilton",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3367",
        Branch_Information: "Hamilton East",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3368",
        Branch_Information: "Hamilton North",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3369",
        Branch_Information: "Hillcrest",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3370",
        Branch_Information: "Huntly",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3371",
        Branch_Information: "Matamata",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3372",
        Branch_Information: "Melville",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3373",
        Branch_Information: "Morrinsville",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3374",
        Branch_Information: "Ngaruawahia",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3375",
        Branch_Information: "Otorohanga",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3376",
        Branch_Information: "Paeroa",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3377",
        Branch_Information: "Putaruru",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3378",
        Branch_Information: "Raglan Agency",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3379",
        Branch_Information: "Taumarunui",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3380",
        Branch_Information: "Te Aroha",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3381",
        Branch_Information: "Te Awamutu",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3382",
        Branch_Information: "Te Kuiti",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3383",
        Branch_Information: "Thames",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3384",
        Branch_Information: "Tokoroa",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3385",
        Branch_Information: "Waikato University",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3386",
        Branch_Information: "Ward Street",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3387",
        Branch_Information: "Waihi",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3388",
        Branch_Information: "Waikato Hospital",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3389",
        Branch_Information: "Chartwell",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3390",
        Branch_Information: "Cashflow",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3391",
        Branch_Information: "Whitianga",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3392",
        Branch_Information: "Whangamata",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3393",
        Branch_Information: "Waihi Beach",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3395",
        Branch_Information: "Training Department",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3396",
        Branch_Information: "Processing Department",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3399",
        Branch_Information: "Head Office",
        Bank_Number: "17",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3501",
        Branch_Information: "Greerton",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3502",
        Branch_Information: "Kawerau",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3503",
        Branch_Information: "Mt Maunganui",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3504",
        Branch_Information: "Murupara",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3505",
        Branch_Information: "Opotiki",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3506",
        Branch_Information: "Rotorua",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3507",
        Branch_Information: "Taupo",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3508",
        Branch_Information: "Tauranga Centre",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3509",
        Branch_Information: "Te Puke",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3510",
        Branch_Information: "Whakatane",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3511",
        Branch_Information: "Kopeopeo Service Centre",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3512",
        Branch_Information: "Edgecumbe",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3513",
        Branch_Information: "Tauranga South",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3514",
        Branch_Information: "Katikati",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3515",
        Branch_Information: "Harrington",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3516",
        Branch_Information: "Richardson Street",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3517",
        Branch_Information: "Pukaki Street",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3518",
        Branch_Information: "16Th Avenue",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3519",
        Branch_Information: "Cherrywood",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3520",
        Branch_Information: "Head Office",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3521",
        Branch_Information: "Papamoa",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3522",
        Branch_Information: "Bayfair",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3523",
        Branch_Information: "Te Ngae",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3524",
        Branch_Information: "Ngongotaha",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3525",
        Branch_Information: "Tauranga Special Services",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3526",
        Branch_Information: "Welcome Bay",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3527",
        Branch_Information: "Rotorua Lending",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3530",
        Branch_Information: "Money Market",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3550",
        Branch_Information: "Haupapa St Service Centre",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3589",
        Branch_Information: "Clearing Centre",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3590",
        Branch_Information: "Cashflow",
        Bank_Number: "18",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4617",
        Branch_Information: "Timaru",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4618",
        Branch_Information: "Ashburton",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4619",
        Branch_Information: "Waimate",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4620",
        Branch_Information: "Temuka",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4621",
        Branch_Information: "Geraldine",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4622",
        Branch_Information: "Ashburton",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4624",
        Branch_Information: "Highfield",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4626",
        Branch_Information: "Fairlie",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4627",
        Branch_Information: "Northtown",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4629",
        Branch_Information: "Pleasant Point",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4631",
        Branch_Information: "Methven",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4633",
        Branch_Information: "Lending Department",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4635",
        Branch_Information: "Timaru",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4647",
        Branch_Information: "Money Market",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4648",
        Branch_Information: "Head Office",
        Bank_Number: "19",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4121",
        Branch_Information: "Gisborne City",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4122",
        Branch_Information: "Gisborne",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4123",
        Branch_Information: "Wairoa",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4124",
        Branch_Information: "Napier",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4125",
        Branch_Information: "Taradale",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4126",
        Branch_Information: "Finance Centre",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4127",
        Branch_Information: "Hastings Central",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4128",
        Branch_Information: "Hastings Central",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4129",
        Branch_Information: "Havelock North",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4130",
        Branch_Information: "Waipawa",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4131",
        Branch_Information: "Waipukurau",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4132",
        Branch_Information: "Dannevirke",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4133",
        Branch_Information: "Broadway",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4134",
        Branch_Information: "Awapuni",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4135",
        Branch_Information: "Terrace End",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4136",
        Branch_Information: "Hokowhitu",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4137",
        Branch_Information: "Feilding",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4138",
        Branch_Information: "Levin",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4139",
        Branch_Information: "Pahiatua",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4140",
        Branch_Information: "Masterton",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4141",
        Branch_Information: "Carterton",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4143",
        Branch_Information: "Marewa",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4144",
        Branch_Information: "Stortford Lodge",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4145",
        Branch_Information: "Mahora",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4146",
        Branch_Information: "Massey",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4147",
        Branch_Information: "Plaza",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4169",
        Branch_Information: "Money Market",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4170",
        Branch_Information: "Head Office",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4198",
        Branch_Information: "Clearing Centre",
        Bank_Number: "20",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4801",
        Branch_Information: "Head Office",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4802",
        Branch_Information: "Queen Street",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4803",
        Branch_Information: "New Lynn",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4804",
        Branch_Information: "Takapuna",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4805",
        Branch_Information: "Papakura",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4806",
        Branch_Information: "Panmure",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4807",
        Branch_Information: "Birkenhead",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4808",
        Branch_Information: "Newmarket",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4809",
        Branch_Information: "Henderson",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4810",
        Branch_Information: "Pakuranga",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4811",
        Branch_Information: "Manurewa",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4812",
        Branch_Information: "Browns Bay",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4813",
        Branch_Information: "Remuera",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4814",
        Branch_Information: "Papatoetoe",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4815",
        Branch_Information: "Howick",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4816",
        Branch_Information: "Royal Oak",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4817",
        Branch_Information: "St Heliers",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4818",
        Branch_Information: "Orewa",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4819",
        Branch_Information: "Milford",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4820",
        Branch_Information: "Pukekohe",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4821",
        Branch_Information: "Devonport",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4822",
        Branch_Information: "Mid City",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4823",
        Branch_Information: "St Lukes",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4824",
        Branch_Information: "Glenfield",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4825",
        Branch_Information: "Mt Roskill",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4826",
        Branch_Information: "Manukau City",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4827",
        Branch_Information: "Glen Eden",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4828",
        Branch_Information: "Ponsonby",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4829",
        Branch_Information: "Whangarei",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4830",
        Branch_Information: "Kerikeri",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4831",
        Branch_Information: "Whangaparaoa",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4833",
        Branch_Information: "Manukau Institute Of Technology",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4895",
        Branch_Information: "Money Market",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4897",
        Branch_Information: "Clearing Centre",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4898",
        Branch_Information: "Cashflow",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4899",
        Branch_Information: "Accounting Department",
        Bank_Number: "21",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4000",
        Branch_Information: "Wanganui",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4002",
        Branch_Information: "Bulls",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4003",
        Branch_Information: "Marton",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4004",
        Branch_Information: "Ohakune",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4005",
        Branch_Information: "Patea",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4006",
        Branch_Information: "Ohakune (Was Raetihi)",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4007",
        Branch_Information: "Taihape",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4008",
        Branch_Information: "Waverley",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4009",
        Branch_Information: "Marton (Was Hunterville)",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4021",
        Branch_Information: "Wicksteed Terrace",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4022",
        Branch_Information: "Wanganui East Service Centre",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4023",
        Branch_Information: "Aramoho Service Centre",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4024",
        Branch_Information: "Springvale Service Centre",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4025",
        Branch_Information: "Gonville Service Centre",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4028",
        Branch_Information: "Cashflow",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4030",
        Branch_Information: "Wanganui Regional Office",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4031",
        Branch_Information: "New Plymouth",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4032",
        Branch_Information: "Hawera",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4033",
        Branch_Information: "Stratford",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "4049",
        Branch_Information: "Wanganui Regional Office",
        Bank_Number: "22",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3700",
        Branch_Information: "Wellington",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3701",
        Branch_Information: "Wellington",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3702",
        Branch_Information: "Wellington",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3703",
        Branch_Information: "Wellington",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3704",
        Branch_Information: "Wellington",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3712",
        Branch_Information: "The Oaks",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3714",
        Branch_Information: "Johnsonville",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3716",
        Branch_Information: "Kilbirnie",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3718",
        Branch_Information: "Karori",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3720",
        Branch_Information: "Lower Hutt",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3724",
        Branch_Information: "Paremata",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3730",
        Branch_Information: "Naenae",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3732",
        Branch_Information: "Newtown",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3734",
        Branch_Information: "Petone",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3736",
        Branch_Information: "Porirua",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3738",
        Branch_Information: "Paraparaumu",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3746",
        Branch_Information: "Stokes Valley",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3748",
        Branch_Information: "Tawa",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3750",
        Branch_Information: "The Terrace",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3754",
        Branch_Information: "Upper Hutt",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3756",
        Branch_Information: "Lambton Quay",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3757",
        Branch_Information: "Waikanae",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3758",
        Branch_Information: "Wainuiomata",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3760",
        Branch_Information: "Willis Street",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3762",
        Branch_Information: "Otaki",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3765",
        Branch_Information: "Molesworth Street",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3770",
        Branch_Information: "International",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3772",
        Branch_Information: "Money Market",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3780",
        Branch_Information: "Clearing Centre",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3784",
        Branch_Information: "Lending Department",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3786",
        Branch_Information: "Lending Department",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3787",
        Branch_Information: "Staff",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3788",
        Branch_Information: "Lending Department",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3792",
        Branch_Information: "Clearing Centre",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3793",
        Branch_Information: "Cashflow",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3794",
        Branch_Information: "Cashflow",
        Bank_Number: "23",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3798",
        Branch_Information: "Head Office",
        Bank_Number: "23",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4310",
        Branch_Information: "Hokitika",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4311",
        Branch_Information: "Training Branch",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4312",
        Branch_Information: "Training Branch",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4315",
        Branch_Information: "Lending Department",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4316",
        Branch_Information: "Head Office",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4319",
        Branch_Information: "Commercial Division",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4320",
        Branch_Information: "Greymouth",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4321",
        Branch_Information: "High Street",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4330",
        Branch_Information: "Westport",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4335",
        Branch_Information: "Murchison",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4336",
        Branch_Information: "Nelson",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4337",
        Branch_Information: "Richmond",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4338",
        Branch_Information: "Blenheim",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ASB Bank",
        Branch_Number: "4340",
        Branch_Information: "Reefton",
        Bank_Number: "24",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2500",
        Branch_Information: "Countrywide",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2501",
        Branch_Information: "Cheques Department (Central Processing)",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2502",
        Branch_Information: "Cheques Department (Central Processing)",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2503",
        Branch_Information: "Cheques Department (Central Processing)",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2509",
        Branch_Information: "Cheques Department (Central Processing)",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2510",
        Branch_Information: "Cheques Department (Central Processing)",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2520",
        Branch_Information: "Cheques Department (Central Processing)",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2521",
        Branch_Information: "Queenstown",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2522",
        Branch_Information: "Ward Street, Hamilton",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2523",
        Branch_Information: "Rotorua",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2524",
        Branch_Information: "Tauranga",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2525",
        Branch_Information: "Whakatane",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2526",
        Branch_Information: "Gisborne",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2527",
        Branch_Information: "Palmerston North",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2528",
        Branch_Information: "New Plymouth",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2529",
        Branch_Information: "Napier",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2531",
        Branch_Information: "Hastings",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2532",
        Branch_Information: "Wanganui",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2533",
        Branch_Information: "Lower Hutt",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2534",
        Branch_Information: "Upper Hutt",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2535",
        Branch_Information: "Masterton",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2536",
        Branch_Information: "Lambton Quay",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2537",
        Branch_Information: "Manners Street",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2538",
        Branch_Information: "Porirua",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2539",
        Branch_Information: "Johnsonville",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2540",
        Branch_Information: "Nelson",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2541",
        Branch_Information: "Blenheim",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2542",
        Branch_Information: "Christchurch",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2543",
        Branch_Information: "Sydenham",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2544",
        Branch_Information: "Armagh Street",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2545",
        Branch_Information: "Riccarton",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2546",
        Branch_Information: "Papanui",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2547",
        Branch_Information: "Timaru",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2548",
        Branch_Information: "Dunedin",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2549",
        Branch_Information: "Invercargill",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2550",
        Branch_Information: "FX Christchurch",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2551",
        Branch_Information: "FX Wellington",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2552",
        Branch_Information: "Merivale",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2553",
        Branch_Information: "Oamaru",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2554",
        Branch_Information: "Ashburton",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2555",
        Branch_Information: "Hornby",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2556",
        Branch_Information: "Linwood City",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2557",
        Branch_Information: "Shirley",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2558",
        Branch_Information: "Richmond",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2559",
        Branch_Information: "Lambton Quay North",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2560",
        Branch_Information: "Feilding",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2561",
        Branch_Information: "Levin",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2562",
        Branch_Information: "Palmerston North Money Shop",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2563",
        Branch_Information: "Tokoroa",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2564",
        Branch_Information: "Te Awamutu",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2565",
        Branch_Information: "Taupo",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2566",
        Branch_Information: "Kawerau",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2567",
        Branch_Information: "Mt Maunganui",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2568",
        Branch_Information: "Victoria Street, Hamilton",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2569",
        Branch_Information: "Frankton",
        Bank_Number: "25",
    },
    {
        Bank_Name: "ANZ Bank New Zealand",
        Branch_Number: "2570",
        Branch_Information: "Cambridge",
        Bank_Number: "25",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3801",
        Branch_Information: "Head Office",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3802",
        Branch_Information: "International Division",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3803",
        Branch_Information: "Card Services Division",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3805",
        Branch_Information: "TBNZ Payments",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3816",
        Branch_Information: "Southern Support Centre - Christchurch",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3817",
        Branch_Information: "Central Support Centre - Wellington",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3818",
        Branch_Information: "Northern Support Centre - Hamilton",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3820",
        Branch_Information: "Southern Teleservicing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3821",
        Branch_Information: "Hamilton Clearing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3822",
        Branch_Information: "Northern Teleservicing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3823",
        Branch_Information: "Christchurch Clearing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3824",
        Branch_Information: "Invercargill Clearing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3825",
        Branch_Information: "Auckland Clearing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "Westpac",
        Branch_Number: "3826",
        Branch_Information: "Palmerston North Clearing Centre",
        Bank_Number: "27",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2901",
        Branch_Information: "Auckland",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2902",
        Branch_Information: "Wellington",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2903",
        Branch_Information: "Auckland-Queen Street Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2904",
        Branch_Information: "Auckland - Corporate",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2906",
        Branch_Information: "New Zealand Management",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2907",
        Branch_Information: "Christchurch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2908",
        Branch_Information: "Auckland - Queen Street Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2909",
        Branch_Information: "Botany Town Premier",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2910",
        Branch_Information: "Takapuna",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2911",
        Branch_Information: "Corporate Partners Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2912",
        Branch_Information: "HSBC Botany Downs",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2915",
        Branch_Information: "Wellington Mortgage Centre",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2916",
        Branch_Information: "Takapuna",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2918",
        Branch_Information: "Takapuna",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2921",
        Branch_Information: "Botany Town Centre Personal Banking",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2922",
        Branch_Information: "Botany Town Centre Commercial Banking",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2931",
        Branch_Information: "Alliances Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2932",
        Branch_Information: "Customer Services Centre Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2933",
        Branch_Information: "PFS Christchurch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2934",
        Branch_Information: "Private Clients Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2935",
        Branch_Information: "HSBC One Queen Street - Alliance Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2936",
        Branch_Information: "HSBC One Queen Street - Retail Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2937",
        Branch_Information: "HSBC One Queen Street - Commercial Branch",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2940",
        Branch_Information: "Payment Services",
        Bank_Number: "30",
    },
    {
        Bank_Name: "HSBC New Zealand",
        Branch_Number: "2948",
        Branch_Information: "Treasury",
        Bank_Number: "30",
    },
    {
        Bank_Name: "Citibank N.A.",
        Branch_Number: "2825",
        Branch_Information: "Auckland - Wholesale",
        Bank_Number: "31",
    },
    {
        Bank_Name: "Citibank N.A.",
        Branch_Number: "2826",
        Branch_Information: "Auckland - Wholesale",
        Bank_Number: "31",
    },
    {
        Bank_Name: "Citibank N.A.",
        Branch_Number: "2827",
        Branch_Information: "Auckland - Wholesale",
        Bank_Number: "31",
    },
    {
        Bank_Name: "Citibank N.A.",
        Branch_Number: "2828",
        Branch_Information: "Auckland - Wholesale",
        Bank_Number: "31",
    },
    {
        Bank_Name: "Citibank N.A.",
        Branch_Number: "2829",
        Branch_Information: "Auckland - Wholesale",
        Bank_Number: "31",
    },
    {
        Bank_Name: "Citibank N.A.",
        Branch_Number: "2840",
        Branch_Information: "Auckland - Wholesale",
        Bank_Number: "31",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9000",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9001",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9002",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9003",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9004",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9005",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9006",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9007",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9008",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9009",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9010",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9011",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9012",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9013",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9014",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9015",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9016",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9017",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9018",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9019",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9020",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9021",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9022",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9023",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9024",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9025",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9026",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9027",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9028",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9029",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9030",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9031",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9032",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9033",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9034",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9035",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9036",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9037",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9038",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9039",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9040",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9041",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9042",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9043",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9044",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9045",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9046",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9047",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9048",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9049",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9050",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9051",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9052",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9053",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9054",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9055",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9056",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9057",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9058",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9059",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9060",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9061",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9062",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9063",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9064",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9065",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9066",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9067",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9068",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9069",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9070",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9071",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9072",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9073",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9074",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9075",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9076",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9077",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9078",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9079",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9080",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9081",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9082",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9083",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9084",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9085",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9086",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9087",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9088",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9089",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9090",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9091",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9092",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9093",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9094",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9095",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9096",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9097",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9098",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9099",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9100",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9101",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9102",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9103",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9104",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9105",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9106",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9107",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9108",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9109",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9110",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9111",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9112",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9113",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9114",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9115",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9116",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9117",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9118",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9119",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9120",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9121",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9122",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9123",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9124",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9125",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9126",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9127",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9128",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9129",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9130",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9131",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9132",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9133",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9134",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9135",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9136",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9137",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9138",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9139",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9140",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9141",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9142",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9143",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9144",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9145",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9146",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9147",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9148",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9149",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9150",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9151",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9152",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9153",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9154",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9155",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9156",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9157",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9158",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9159",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9160",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9161",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9162",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9163",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9164",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9165",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9166",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9167",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9168",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9169",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9170",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9171",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9172",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9173",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9174",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9175",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9176",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9177",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9178",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9179",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9180",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9181",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9182",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9183",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9184",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9185",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9186",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9187",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9188",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9189",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9190",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9191",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9192",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9193",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9194",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9195",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9196",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9197",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9198",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9199",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9200",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9201",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9202",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9203",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9204",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9205",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9206",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9207",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9208",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9209",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9210",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9211",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9212",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9213",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9214",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9215",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9216",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9217",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9218",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9219",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9220",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9221",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9222",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9223",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9224",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9225",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9226",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9227",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9228",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9229",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9230",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9231",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9232",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9233",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9234",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9235",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9236",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9237",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9238",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9239",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9240",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9241",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9242",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9243",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9244",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9245",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9246",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9247",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9248",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9249",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9250",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9251",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9252",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9253",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9254",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9255",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9256",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9257",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9258",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9259",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9260",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9261",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9262",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9263",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9264",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9265",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9266",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9267",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9268",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9269",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9270",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9271",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9272",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9273",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9274",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9275",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9276",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9277",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9278",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9279",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9280",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9281",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9282",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9283",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9284",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9285",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9286",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9287",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9288",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9289",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9290",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9291",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9292",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9293",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9294",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9295",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9296",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9297",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9298",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9299",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9300",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9301",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9302",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9303",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9304",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9305",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9306",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9307",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9308",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9309",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9310",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9311",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9312",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9313",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9314",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9315",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9316",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9317",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9318",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9319",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9320",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9321",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9322",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9323",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9324",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9325",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9326",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9327",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9328",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9329",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9330",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9331",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9332",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9333",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9334",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9335",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9336",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9337",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9338",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9339",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9340",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9341",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9342",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9343",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9344",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9345",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9346",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9347",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9348",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9349",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9350",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9351",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9352",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9353",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9354",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9355",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9356",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9357",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9358",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9359",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9360",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9361",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9362",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9363",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9364",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9365",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9366",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9367",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9368",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9369",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9370",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9371",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9372",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9373",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9374",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9375",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9376",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9377",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9378",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9379",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9380",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9381",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9382",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9383",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9384",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9385",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9386",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9387",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9388",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9389",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9390",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9391",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9392",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9393",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9394",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9395",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9396",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9397",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9398",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9399",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9400",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9401",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9402",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9403",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9404",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9405",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9406",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9407",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9408",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9409",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9410",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9411",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9412",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9413",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9414",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9415",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9416",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9417",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9418",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9419",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9420",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9421",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9422",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9423",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9424",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9425",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9426",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9427",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9428",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9429",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9430",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9431",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9432",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9433",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9434",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9435",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9436",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9437",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9438",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9439",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9440",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9441",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9442",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9443",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9444",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9445",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9446",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9447",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9448",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9449",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9450",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9451",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9452",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9453",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9454",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9455",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9456",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9457",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9458",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9459",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9460",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9461",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9462",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9463",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9464",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9465",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9466",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9467",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9468",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9469",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9470",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9471",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9472",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9473",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9474",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9475",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9476",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9477",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9478",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9479",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9480",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9481",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9482",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9483",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9484",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9485",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9486",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9487",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9488",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9489",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9490",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9491",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9492",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9493",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9494",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9495",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9496",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9497",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9498",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Kiwibank",
        Branch_Number: "9499",
        Branch_Information: "Kiwibank Limited Head Office",
        Bank_Number: "38",
    },
    {
        Bank_Name: "Bank of China NZ Ltd",
        Branch_Number: "8800",
        Branch_Information: "Auckland Branch",
        Bank_Number: "88",
    },
    {
        Bank_Name: "Bank of China NZ Ltd",
        Branch_Number: "8801",
        Branch_Information: "Auckland Branch",
        Bank_Number: "88",
    },
    {
        Bank_Name: "Bank of China NZ Ltd",
        Branch_Number: "8802",
        Branch_Information: "Auckland Branch",
        Bank_Number: "88",
    },
    {
        Bank_Name: "Bank of China NZ Ltd",
        Branch_Number: "8803",
        Branch_Information: "Auckland Branch",
        Bank_Number: "88",
    },
    {
        Bank_Name: "Bank of China NZ Ltd",
        Branch_Number: "8805",
        Branch_Information: "Auckland Branch",
        Bank_Number: "88",
    },
];
